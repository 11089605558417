import React from "react";

export function CircleCross(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      {...props}
      viewBox="0 0 36 36"
    >
      <rect width="36" height="36" fill="#EBEBF0" rx="18"></rect>
      <path
        fill="#212121"
        d="M12.807 12.925l.055-.063a.667.667 0 01.88-.056l.063.056L18 17.057l4.196-4.195a.667.667 0 01.942.943L18.944 18l4.194 4.195c.24.24.26.619.056.88l-.056.063a.667.667 0 01-.88.055l-.062-.055L18 18.943l-4.195 4.195a.667.667 0 01-.943-.943L17.057 18l-4.195-4.195a.667.667 0 01-.055-.88l.055-.063-.055.063z"
      ></path>
    </svg>
  );
}
