import * as React from "react";

export const SliderArrowRight = (props) => {
  return (
    <svg
      width={46}
      height={46}
      viewBox="0 0 46 46"
      fill="none"
      className="arrow-slider"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={23} cy={23} r={23} fill="#299cf7" fillOpacity={0.13} />
      <path
        d="M35.0607 26.0607C35.6464 25.4749 35.6464 24.5251 35.0607 23.9393L25.5147 14.3934C24.9289 13.8076 23.9792 13.8076 23.3934 14.3934C22.8076 14.9792 22.8076 15.9289 23.3934 16.5147L31.8787 25L23.3934 33.4853C22.8076 34.0711 22.8076 35.0208 23.3934 35.6066C23.9792 36.1924 24.9289 36.1924 25.5147 35.6066L35.0607 26.0607ZM12 26.5H34V23.5H12V26.5Z"
        fill="#299cf7"
      />
    </svg>
  );
};
