import React from "react";

function Facebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "32"}
      height={props.height ? props.height : "32"}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={props.color}
        d="M16.711 28h-4.027V16H10v-4.125h2.684v-2.4c0-3.45.806-5.475 4.363-5.475H20v4.125h-1.88c-1.408 0-1.476.6-1.476 1.65v2.1H20l-.336 4.124H16.71V28z"
      ></path>
    </svg>
  );
}

export default Facebook;
