import "./App.css";
import Router from "./router/Router";
import { BrowserRouter } from "react-router-dom";
import "./Assets/Styles/style.scss";
import "./Assets/Styles/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function App(props) {
  return (
    <div className="App h-100" style={{ position: "relative" }}>
      <BrowserRouter>
        <Router history={props.history} />
      </BrowserRouter>
    </div>
  );
}

export default App;
