import * as React from "react"

function LinkedIn(props) {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M5.188 7a2.188 2.188 0 100-4.375 2.188 2.188 0 000 4.376zM9.442 8.659v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.96 1.81 1.96 3.218v5.904h3.771v-6.657c0-3.27-.704-5.783-4.525-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.442zm-6.142 0h3.775v12.139H3.3V8.658z"
        fill={props.color}
      />
    </svg>
  )
}

export default LinkedIn
