// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
  background-color: ${({ theme }) => theme.primaryColor};
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
`;

export const FaqText = styled.span(
  ({ arabic }) => `
  direction:rtl;
  font-family: ${arabic ? "Cairo" : "Dm Sans"};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
  padding:16px 20px;
  text-align:${arabic ? "right" : "left"};
  direction: ${arabic ? "rtl" : "ltr"};
`
);

// export type FaqQuestionProps = {
//   arabic?: boolean;
// };
// export const FaqQuestion = styled.button<FaqQuestionProps>(
//   ({ arabic }) => `
//   font-family: ${arabic ? "Cairo" : "Dm Sans"};
//   font-style: normal;
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 28px;
//   display: flex;
//   align-items: center;
//   text-align: ${arabic ? "right" : "left"};
//   color: #000000;
//   text-align:${arabic ? "right" : "left"};
//   direction: ${arabic ? "rtl" : "ltr"};
//   background: none;
//   outline: none;
//   border: none;
//   &:focus{
//     border-color: none !important;
//     outline: 0;
//     box-shadow: none !important;
//   }

// `
// );
