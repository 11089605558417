// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const ImgContainer = styled.div(
  ({ background }) => `
    background: ${background};
    height: 200px;   
    background-size: cover;
    background-position: center center;
    border: 5px solid #299cf7;
    border-radius: 15px;
    @media (max-width: 1399px) {
      width: 100%;
      height: 192px;
    } 
    @media (max-width: 992px) {
      width: 100%;
      height: 240px;

    }
  `
);

export const SliderImgCon = styled.div`
  position: relative;
  padding-top: 30px;
  @media (max-width: 992px) {
    padding-top: 25px;
  }
`;

export const SliderImgLogo = styled.img`
  border-radius: 25px;
  @media (max-width: 992px) {
  }
`;

export const LogoContainer = styled.div(
  ({ background }) => `
  width: 70.22px;
  background: ${background};
  height: 34.29px;
  background: #092040;
  border-radius: 50px;
  @media (max-width: 992px) {a
    width: 100%;
  }
  `
);

export const Subtext = styled.div(
  ({ fontSize, textAlign, lineHeight, fontWeight, arabic }) => `
      font-family: Dm Sans;
      font-style: normal;
      font-weight: ${fontWeight};
      font-size: ${fontSize};
      line-height: ${lineHeight ? lineHeight : "30px"};
      text-align: center;
      color: #333333;
      direction: ltr;
      @media (max-width: 992px) {
    
    }
`
);
