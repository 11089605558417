export const arabicMajors = {
  Accounting: "المحاسبة",
  Biology: "الأحياء",
  Chemistry: "الكيمياء",
  "Chemical Engineering": "الهندسة الكيميائية",
  "Civil Engineering": "الهندسة المدنية",
  "Computer Science": "علوم الكمبيوتر",
  "Construction Management": "إدارة الانشاءات",
  Economics: "الاقتصاد",
  "Electrical Engineering": "الهندسة الكهربائية",
  "English Literature": "اللغة الإنجليزية وآدابها",
  "Environmental Science": "العلوم البيئية",
  "Actuarial Science": "العلوم الإكتوارية",
  "Aeronautical Engineering": "هندسة الطيران",
  "Architectural Engineering": "الهندسة المعمارية",
  Architecture: "العمارة",
  "Art & Design / Interior Design": "التصميم الداخلي",
  "Business Mangment": "إدارة الأعمال",
  "Communication - Journalism": "الصحافة والإعلام",
  "Communication & Media Studies": "الإعلام والاتصالات",
  "Computer Engineering": "هندسة الكمبيوتر",
  "Cyber Security": "الأمن السيبراني",
  "Data Science": "علم البيانات",
  Dentistry: "طب الأسنان",
  "Electronic Engineering": "الهندسة الإكترونية",
  Entrepreneurship: "ريادة الأعمال",
  "Environmental Engineering": "الهندسة البيئية",
  Finance: "التمويل",
  Geology: "الجيولوجيا",
  "Graphic Design": "تصميم الجرافيك",
  History: "التاريخ",
  "Hospitality Administration": "إدارة الفنادق والضيافة",
  "Human Resources Management": "إدارة الموارد البشرية",
  "Industrial Engineering": "الهندسة الصناعية",
  "International Business": "التجارة الدولية",
  "International Relations": "العلاقات الدولية",
  Linguistics: "اللغويات",
  "Management Information Systems": "إدارة نظم المعلومات",
  Marketing: "التسويق",
  "Mass Communications": "الإعلام والتواصل الجماهيري",
  Mathematics: "الرياضيات",
  Medicine: "الطب البشري",
  Music: "الموسيقى",
  Nursing: "التمريض",
  Nutrition: "علوم التغذية",
  Pharmacy: "الصيدلة",
  Physics: "الفيزياء",
  Physiotherapy: "العلاج الطبيعي",
  "Political Science": "العلوم السياسية",
  Psychology: "علم النفس",
  Radiography: "التصوير الإشعاعي",
  "Renewable & Sustainable Energy Engineering":
    "هندسة الطاقة المتجددة والمستدامة",
  Sociology: "علم الاجتماع",
  Education: "التربية",
  Statistics: "الإحصاء",
  "Theatre and Dance": "الفنون المسرحية",
  "Veterinary Medicine": "الطب البيطري",
  Law: "الحقوق",
  "Agricultural science": "العلوم الزراعية",
  "Mechanical Engineering": "الهندسة الميكانيكية",
};

export const majorDesc = {
  Accounting:
    "تتم في تخصص المحاسبة دراسة وتحليل التقارير المالية، وتحديد الربح والخسارة لفترة زمنية معينة، بالإضافة إلى توضيح قيمة وطبيعة الموارد المتاحة للشركة، والوسائل المستخدمة لتمويل هذه الموارد، وما هي الآثار المحتملة لاستخدامها.",
  Biology:
    "الأحيهو علم دراسة الكائنات الحية من حيث بنيتها، وتغذيتها، وتكاثرها، وطبيعتها، وصفاتها، وأنواعها، والقوانين التي تحكم طرق عيشها وتطورها وتفاعلها مع وسطها الطبيعي. وهذا العلم واسع جداً وينقسم لعدة فروع من أهمها علم الكائنات المجهرية وعلم الحيوان وعلم النبات وكذلك علم وظائف الأعضاء والكيمياء الحيوية وعلم البيئة. وتختلف الوظائف باختلاف التخصص والفرع الدقيق. بشكل عام، يكون التوظيف في مختبرات المستشفيات وفي المدارس كمعلمين للمادة والجهات الحكومية التي لها علاقة بالأمراض. اء",
  Chemistry:
    "الهندسة الكيميائية هي احدى فروع الهندسة التي تختص بتصميم الآلات والمحطات وتشغيلها وبنائها، كما أنها تهتم بأداء التفاعلات الكيميائيّة في مجالات الصناعة المختلفة، كإنتاج النفط وتكريره، وإنتاج الأسمدة غير العضوية، وإنتاج المواد الكيميائيّة الدقيقة والبلاستيك والمعادن والورق والألياف الصناعيّة والمواد الغذائيّة، وتستخدم الهندسة الكيميائيّة كلاً من الفيزياء والكيمياء والرياضيات والأحياء؛ لتطوير التفاعلات والتحولات الكيميائيّة، بهدف إنتاج منتجات ومواد مفيدة تعود بالنفع على المجتمعات والدول.",
  "Chemical Engineering":
    "يعد تخصص الهندسة الكيميائية أحد أهم التخصصات المهمة في الحياة المعاصرة، إذ إن الهندسة الكيميائية أحد فروع الهندسة التي تختص بتصميم الآلات والمحطات وتشغيلها وبنائها، كما أنها تهتم بأداء التفاعلات الكيميائيّة في مجالات الصناعة المختلفة، كإنتاج النفط وتكريره، وإنتاج الأسمدة غير العضوية، وإنتاج المواد الكيميائية الدقيقة والبلاستيك والمعادن والورق والألياف الصناعية والمواد الغذائية، وتستخدم الهندسة الكيميائية في سيرورة عملها كلاً من الفيزياء والكيمياء والرياضيات والأحياء؛ لتطوير التفاعلات والتحولات الكيميائيّة، ابتغاء إنتاج منتجات ومواد مفيدة تعود بالنفع على المجتمعات والدول.",
  "Civil Engineering":
    "تختص الهندسة المدنية بتصميم وتطوير مشاريع البنية التحتية، بحيث تعمل على تطوير المشاريع على نطاق واسع مثل؛ أنظمة النقل الوطنية، أو شبكات إمدادات المياه، أو تطوير المباني والطرق المحلية، وتشمل الهندسة المدنية مزيجاً من العديد من الوظائف مثل؛ التخطيط والتحليل، والتصميم، وإدارة الميزانية، والمسح، وإدارة البناء، وما يميزها أنّها مهنة تتكيّف مع تطورات المجتمع وتحقق كل احتياجاته كالنظر في الاهتمامات البيئية المعاصرة، فتجد حلولًا لقضايا التلوث، وندرة إمدادات المياه مع الحفاظ على مصادر الطاقة المستدامة.",
  "Computer Science":
    "يشمل تخصص علم الحاسوب دراسة الأجهزة والبرمجيات والشبكات، وجميع العمليات التي يقوم بها الحاسوب والمتمثلة بمجموعة من المهام والإجراءات المعقدة، كما ويحتوي الحاسوب على عدد من الخوارزميات التي تمكن آلة الحوسبة من تحويل المعلومات وإتمام المهام ويشمل علم الحاسب على استخدامات الحواسيب في الحسابات، ومعالجة البيانات، والتحكم بالنظم، كما ويشمل الأعمال الهندسية، مثل: تصميم الحاسوب، والبرمجيات، بالإضافة إلى الأعمال النظرية والرياضية، مثل: تصميم الخوارزميات ويمكن أن يعمل الدارس لهذا التخصص في مجال تصميم المواقع الإلكترونية وتطبيقات الهاتف.",
  "Construction Management":
    "تخصص إدارة المنشآت تابع للهندسة المدنية ويقوم الدارس لهذا التخصص بدراسة مخطط وتصميم المباني المنشآت لمعرفة كيفية الحفاظ عليها وإدارتها بشكل احترافي وغير مكلف ويقوم كذلك باستخدام التقنيات الرقمية لاداء المهمات بأسرع وقت ممكن وبأكثر الطرق كفاءة ومن ضمن عمله.",
  Economics:
    "يعَد تخصص الاقتصاد من التخصصات المندرجة ضمن آليات العلم الاجتماعي؛ إذ يهتم بدراسة كل ما يتعلق بالسيرورة المالية للبضائع والسلع واستهلاكها وتوزيعها وإنتاجها وتصديرها، ولا يقتصر مجال الاقتصاد على الساحة المحلية للبلد، بل يهتم بالروابط الاقتصادية على مستوى العالم، ابتغاء تأهيل متخصصين، عبر تزويدهم بالمعلومات اللازمة والتدريبات الضرورية في الجوانب النظرية والتطبيقية، لتلبية احتياجات سوق العمل ومتطلباته.",
  "Electrical Engineering":
    "تعرف الهندسة الكهربائية على أنها فرع من فروع الهندسة المعنية بالتطبيق العملي للكهرباء بكافة أشكالها، بِما في ذلك التطبيقات الإلكترونية؛ حيث تتعامل الهندسة الكهربائية مع أجهزة وأنظمة الطاقة الكهربائية، والضوئية، وتقوم على تصميم وتطوير الآلات والمعدات الكهربائية، وأنظمة التحكم الكهربائية الجديدة، بالإضافة إلى حل المشاكل واختبار المعدات، وأساس هذه الهندسة هو إنتاج وتوزيع الطاقة الكهربائية على نطاق واسع.",
  "English Literature":
    "يتم تدريس مجالات الأدب، والشعر، واللغة، والكتابة ضمن تخصص اللغة الإنجليزية، فهو يتعمق في تحليل الكتابات القصصية لكتاب وشعراء على مر التاريخ كشكسبير وغيره، بالإضافة إلى تحليل أعمال عدة تعود لحضارات إنسانية مختلفة، مما يعزز من إمكانيات الدارس الإبداعية، والأخلاقية، ومن ناحية أخرى فإن دراسة الأدب تمكن الطلاب من الإجابة على التساؤلات المتعلقة بالحياة الخاصة.",
  "Environmental Science":
    "مجال العلوم البيئية هو مجال متعدد التخصصات حيث يشترك مع مجالات دراسية أخرى مثل الجغرافيا، الأحياء، علم الكائنات البيئية، الكيمياء البيئية، وعلوم الغلاف الجوي وغيرها. ويركز علماء البيئة جهودهم على موضوعات مثل فهم عمليات الأرض وتقييم نُظم الطاقة المستدامة ومكافحة التلوث والتخفيف من حدته وإدارة الموارد الطبيعية وتبعات الاحترار العالمي.",
  "Actuarial Science":
    " يقوم تخصص العلوم الاكتوارية بتقييم المخاطر المالية في مجالي التأمين والتمويل باستخدام الأساليب الرياضية والإحصائية، وقد أصبحت العلوم الإكتوارية نظامًا رياضيًا رسميًا في أواخر القرن السابع عشر مع زيادة الطلب على التغطية التأمينية طويلة الأمد مثل التأمين على الحياة والمعاشات السنوية، وعلى مر التاريخ استخدمت العلوم الإكتوارية نماذج لبناء الجداول والأقساط، كما تركز العلوم الإكتوارية أيضًا على تحليل معدلات العجز والاعتلال والوفيات والخصوبة وحالات الطوارئ.",
  "Aeronautical Engineering":
    "هندسة الطيران هي فرع من فروع الهندسة يختص ببناء ونصميم وتجميع وتطوير الطائرات، ويهتم مهندسو الطيران بالتطوير في المجال الجوي من حيث تصميم المطارات وتقنيات الملاحة، وكذلك تخطيط المطارات، ويسعى المحترفون في هذا المجال إلى تجربة ابتكارات مجال التكنولوجيا بالنسبة لطائرات الاختبار، ومحاكاة ظروف الطيران، وغيرها.",
  "Architectural Engineering":
    "يعرف اختصاص هندسة العمارة بأنه مرتبط بالفن وعلم الهندسة في الوقت ذاته فإن فن العمارة هو ابتكار وتخيل الشكل الخارجي للبناء أو العقار, ومن ثم إمكانية رسمه وتصميمه على الورق بأدق التفاصيل، وبعد ذلك متابعة وتوجيه المهندسين المدنيين خلال فترة التشييد، وكل هذا ضمن معايير علمية محددة تضمن أن يكون البناء صالحاً للعيش فيه بسلام وأمان، وإمكانية مواجهته للحوادث الطبيعية العادية والطارئة. كما أن من وظائف المهندس المعماري مراعاة الوقائع البيئية والجيولوجية لموقع البناء، وومراعاة ظروف المنشآت المجاورة أثناء القيام بالتصميم الجديد، سواء كان بناءً سكنياً، أو منشأة صناعية أو خدمية، أو جسراً أو نفقاً.",
  Architecture:
    "يعرف هذا التخصص بعلم وفن البناء ويهتم هذا التخصص بإبداع تصميمات المباني من حيث التخطيط والإنشاء والإضاءة ويتطلب أن يكون الطالب شغوفا بالرسم ولا يمانع استخدام الحاسوب وتعلم برمجيات مخصصة بالرسم وتلعب الأرصاد الجوية دورًا وظيفيًا مهماً؛ نظرًا لمدى تأثير الظروف الجوية على الحياة، إذ تتيح عملية التنبؤ بالجو القدرة على إدارة الحياة، بحيث تتمكن الدول من الاستعداد جيدًا في مواجهة ظروف الطقس القاسية كالأعاصير والعواصف الثلجية في سبيل منع حدوث الكوارث.",
  "Art & Design / Interior Design":
    "يعرف التصميم الداخلي بأنه فن تخطيط وتصميم وتجميل المساحات التي من صنع البشر، ويرتبط هذا المجال ارتباطاً وثيقاً بهندسة العمارة، وعلى الرغم من وجود اهتمام واضح بجماليات الأماكن وتصميمها على مر العصور؛ إلا أن مجال التصميم الداخلي ما زال حديث النشأة نسبياً.",
  "Business Mangment":
    "يهتم تخصص إدارة الأعمال بطريقة تأهيل وتدريب الأفراد لتنظيم أعمال الشركات أو المنشآت، من خلال عدة عمليات، منها: التوجيه، والمراقبة، والتخطيط، والتحكم، بدءاً من إعداد عائدات الضرائب إلى الإشراف على الموظفين، يكتسب الطلبة في هذا التخصص معلومات تختص بعدة مجالات؛ كالإدارة، والمحاسبة، والتسويق، واتخاذ القرارات التجارية، والتنظيم، وإدارة الموارد البشرية.",
  "Communication - Journalism":
    "يدرس تخصص الصحافة والاتصال محتوى وتاريخ وآثار وسائل الإعلام المختلفة على الجمهور، وتعتمد دراسة الإعلام على العلوم الاجتماعية والإنسانية بشكل خاص، ويهتم تخصص الصحافة والإعلام بالعديد من الحقول العلمية مثل الاقتصاد والمحاسبة والرياضة والسياسة وغيرها ما يفسر أهمية هذا العلم وتأثيره على العلوم الأخرى. ويسعى تخصص الأعلام إلى تهيئة طلاب قادرين على العمل بالإعلام سواء أكان مرئي أو مسموع أو مقروء ونقل الرسالة الإعلامية بمهنية وحيادية، فالإعلامي يُعد البرامج ويكتب النصوص التلفزيونية ويحررها ويعد النشرات الإخباريّة ويقدمها.",
  "Communication & Media Studies":
    "الاتصال هو التخصص الذي يركز على كل أشكال المعرفة اللازمة من أجل التواصل الجيد على أعلى المستويات شفهيًا وكتابيًا وبمختلف الأدوات والوسائل الإعلامية وسيتعلم المتخصص في هذا المجال مجموعة واسعة ومتنوعة من المعارف على صلة بهذا الشأن من خطابة وكتابة وصياغة إلى مختلف أشكال التواصل والعلاقات ومهاراته التي تلزم العاملين في هذا المجال هذا التخصص يمكن الشخص من العمل في الإذاعات الإعلامية كالراديو والتلفزيون بالإضافة إلى الصحافة والإنترنت والعلاقات العامة بالإضافة إلى مكاتب البث الإذاعي والتلفزيوني والصحافة والتحرير وصناعة الأخبار والقصص الصحفية.",
  "Computer Engineering":
    "هندسة الكمبيوتر هي إحدى فروع الهندسة الإلكترونية، وتتخصص بشكلٍ عام في ثلاث مجالات هي: عتاد أجهزة الحاسوب، وبرمجيات أجهزة الحاسوب، وتطبيقات برمجيات الحاسوب. ويقوم مهندسو الحاسوب بتصميم الحواسيب وبرمجياتها، ومن ثم يشرفون على هذه التصاميم من لحظة التخطيط لها وحتى الانتهاء من بنائها. إن مهندس الحاسوب الناجح لا بد له أن يجمع ما بين التعليم، والمهارات الشخصية أثناء عمله. وعادةً ما يعمل مهندسو الحاسوب في مختبرات الأبحاث، والتي تعنى بتصميم واختبار مختلَف أنواع أجهزة الحاسوب.",
  "Cyber Security":
    " يعتبر تخصص الأمن السيبراني أو أمن الشبكات من التخصصات التابعة إلى علم الحاسوب والغرض منه حماية المعلومات والبرامج والشبكات من الفيروسات والهجوم الاكتروني وحماية المستخدمين من ابتزاز بعض قراصنة المعلومات الذين يسعون إلى سرقة البيانات البنكية أو المعلومات الشخصية وكذلك حماية الدول من الأخطار الإلكترونية التي قد تتعرض لها. وممكن آن يعمل دارس هذا التخصص في البنوك أو في الجهات الحكومية كوزارة الدفاع أو وزارة الداخلية وغيرها. من الجدير بالذكر أنه يتم تعلم لغات البرمجة المختلفة مما يتطلب ساعات طويلة من العمل على جهاز الحاسوب.",
  "Data Science":
    "ويمكن تعريف علم البيانات أنه استخدام أجهزة الحاسوب، ومعداتها، وأنظمة البرمجة، والخوارزميات بهدف حل المشاكل، وتفسير الظواهر الفعلية، حيث يقوم بالاستعانة بنظريات مستمدة من علوم أخرى بما فيها الرياضيات، ونظم المعلومات، والإحصاء، وعلم الحاسوب والأساليب العلمية، والعمليات، والخوارزميات، والأنظمة بغرض استخراج المعرفة، والأفكار من البيانات سواءً كانت هذه البيانات منظمة ام لا.",
  Dentistry:
    "عادةً ما تتطلب دراسة مثل هذا التخصص مستوياتٍ جيدةً في موادٍ دراسيةٍ عديدة، ومنها الكيمياء والعلوم الحياتية، ولأن هذا التخصص يتطلب التعامل المباشر مع الناس؛ فلا بدّ للشخص أن يمتلك مهارات التعامل والتواصل معهم،ولا بدّ من الإشارة الى أن طب الأسنان يتطلب تعاون فريقٍ كاملٍ ليقوم بإجراء العلاجات السنية، ويتكون هذا الفريق من مساعد طبيب الأسنان، وفني مختبر الأسنان، وأخصائي الصحة السنية، ويكون طبيب الأسنان قائد هذا الفريق.",
  "Electronic Engineering":
    "تعرف الهندسة الإلكترونية على أنها أحد فروع الهندسة الكهربائية، التي تهتم بالوصول إلى المعرفة الاحترافية في معالجة وفهم الإلكترونيات، وتأثير العناصر، والأنظمة، والتجهيزات الإلكترونية على هذه الأجهزة، كما تعتبر حقلاً هندسياً عريضاً يضم الكثير من المواضيع الجزئية التي تعالج موضوعات العناصر الإلكترونيّة، وهندسة القياسات الكهربائية، والإلكترونية، بالإضافة إلى الاتصالات وتصميم دارات أنصاف النواقل وجميع تجهيزاتها.",
  Entrepreneurship:
    "تعرف ريادة الأعمال بإنها أسلوب يوفر أطار لكيفية تحويل الفكرة العظيمة إلي عمل تجاري عظيم، مرورًا بكافة مراحل التأسيس والنمو والتمويل بطريقة فعالة وغير تقليدية، مع الحرص على الاستمرارية؛ بغية الحصول على ربح وتحقيق الاستقلال المالي، من خلال تنمية شركة ناشئة تخلق الإلفة والانطباع الإيجابي لدى العملاء والموردين والمستثمرين.",
  "Environmental Engineering":
    "تخصص هندسة البيئة هو أحد التخصصات الهندسية المثيرة ، التى يدرس خلالها الطالب برامجاً متميزة لإعداده بهدف استخدام الطرق الهندسية والرياضية والعلمية  لتصميم أنظمة تساعد على حل مشاكل البيئة ، والتخفيف من أضرار التلوث ، والرصد الدائم والتحكم المستمر فى مراكز تلوث الهواء والأرض والماء ، إلى جانب الطرق العملية لحماية الصحة والأمان فى المنشآت.",
  Finance:
    "يعنى التمويل بتحديد احتياجات الأفراد والمنظمات والشركات من الموارد النقدية وتحديد سبل جمعها واستخدامها مع الأخذ في الحسبان المخاطر المرتبطة بمشاريعهم.",
  Geology:
    "الجيولوجيا تخصص يهتم بدراسة البينية التحتية للأرض الصلبة كما يختص بدراسة الصخور التي تكون طبقات الأرض والعمليات التي تحدث عليها ويهتم علم الجيولوجيا أيضًا بطبقات الأرض والأدوات اللازمة لتحديد التاريخ الجيولوجي لكل الأرض وعمرها، وعلاقتها بباقي الكواكب الأخرى على اعتبار أن كوكب الأرض أحد الكواكب الشمسية.",
  "Graphic Design":
    "هو أحد التخصصات الدراسية المستقلة بذاتها والتي تجمع بين العديد من الفنون، ويعتبر من أحدث الوسائل العصرية التي تقوم فكرته على إيصال رسالة أو مجموعة رسائل يرغب العميل في بثها إلى الجمهور المستهدف من خلال إنشاء تواصل بصري وتقديم بعض التصاميم التي تضم مجموعة رموز، صور، رسومات، أو حتى كلمات.",
  History:
    "التاريخ هو دراسة التغيير بمرور الوقت، وهو يغطي جميع جوانب المجتمع البشري، حيث إن كل من التطورات السياسية والاجتماعية والاقتصادية والعلمية والتكنولوجية والطبية والثقافية والفكرية والدينية والعسكرية كلها جزء من التاريخ، ولأن التاريخ يدور حول التغيير، فلا شيء كان دائمًا بطريقة معينة.",
  "Hospitality Administration":
    "يختص تخصص إدارة الفنادق والضيافة ، بدراسة كل ما يتعلّق بالفنادق، وإدارتها، والعمل بها. ويعنى أيضًا بدراسة كل ما يتعلّق بالمؤسسات التي تتعامل مع الزوار والسياح مثل شركات الطيران، والفنادق، والمنتجعات السياحية، والمطاعم الدولية، والمستشفيات، والسفن البحرية.",
  "Human Resources Management":
    "تخصص ادارة اعمال موارد بشرية هو أحد التخصصات الهامة الذي يساهم بشكل كبير في زيادة القيمة الاقتصادية للمؤسسة ورفع كفاءة الأداء، حيث يتم الاعتماد على خطة استراتيجية بهدف تحليل متطلبات العمل من القوى العاملة، والعمل على تنفيذ هذه المتطلبات من خلال اختيار وسيلة تساعد في تنفيذ الخطة بصورة مثالية، وتلجأ إلى هذا التخصص الكثير من المنظمات والصناعات المتطورة الصناعات الحيوية، فنجد أن هناك ارتباط وثيق مع الموارد البشرية يزداد بمرور الوقت.",
  "Industrial Engineering":
    "تركز الهندسة الصناعية على معرفة طريقة أداء الأشياء والعمليات المختلفة، وجعلها تعمل بشكل أفضل؛ فهي تدرس عمليات التصميم، والتطوير، والتشغيل، والاختبار لمختلَف الأنظمة الصناعية التي تفيد البشر، وتحسن من عملية الإنتاج، كما عمل الهندسة الصناعية على التحسين من أساليب العمل والإنتاج؛ للوصول إلى كلّ ما هو أفضل، ومن مهام المهندس الصناعي خفض تكاليف الإنتاج، وزيادة كفاءة العمليات، كما يهتم بتحسين جودة المنتجات والخدمات التي يتمّ تقديمها.",
  "International Business":
    "تخصص التجارة الدولية يدرس الطالب كافة النظريات الأساسية لمبادئ الاقتصاد والاقتصاد الدولي والتجارة الدولية والمهارات التشغيلية للعمل الفعلي في التجارة الدولية. بالتالي يفيد ذلك سوف العمل والتجارة المحلية والدولية. المناهج تتطور باستمرار لتعكس الاتجاهات التقنية والمتطورة في عالم التجارة الدولية.",
  "International Relations":
    "يعَد تخصص العلاقات الدولية من التخصصات المرتبطة بدراسة جميع العلاقات بين الدول، وهو فرع من العلوم السياسية، ولا يقتصر على ما يتعلق بالسياسة فقط، بل يتصل التخصص بالعلاقات الدولية في أبعادها كلها، الاقتصادية والعقائدية والثقافية والاجتماعية، وما يتعلق بالشؤون الخارجية والداخلية للدول، ويستفيد التخصص من مجالات كثيرة، منها مجالات علم النفس والاجتماع والجغرافيا والتاريخ والقانون الدولي.",
  Linguistics:
    "إن تخصص علم اللغويات هو العلم الذي يدرس اللغة دراسة علمية وبأسلوب علمي. ويصنف هذا التخصص على أنه علميًا وذلك لأنه بناء اللغة، وكيفية تكوين التراكيب اللغوية والمفردات والكلمات، بالإضافة إلى التعرف على الأصوات الموجودة في كل كلمة، ودراسة طريقة وطبيعة نطقها.",
  "Management Information Systems":
    "هو التخصص الذي يعمل على تسهيل التكامل والتحكم والتنسيق والإدارة في المجالات الوظيفية المختلفة مثل الإدارة العامة والإدارة المالية وإدارة المنتجات وإدارة التسويق وإدارة شئون الموظفين. حيث توفر نظم المعلومات فوائد ومعايير لازمة لعملية التطور والتقدم في الشركات والمؤسسات، فتساهم في رفع الإنتاجية وتحسين مستوى الكفاءة في الشركة وتطوير وابتكار خدمات ومنتجات ونماذج للأعمال الحديثة. بالإضافة إلى دورها في تسهيل عملية اتخاذ القرار والتخطيط بشكل أفضل لتحسين استراتيجية الشركة، وتحقيق النتائج المطلوبة بأقل وقت وجهد وتكلفة ممكنة.",
  Marketing:
    "تخصص التسويق يركز على العديد من الطرق لتطوير وبيع المنتجات إلى أسواق العملاء المميزة، وأيضاً تعلم طرق مختلفة لتسويق المنتجات، وخدمات الشركات.",
  "Mass Communications":
    "يركز هذا المجال على مواكبة كل النظريات والتقنيات الحديثة المتعلقة بوسائل الإعلام الاجتماعية ليصبح المتخصص في هذ المجال أكثر قدرة على التعامل مع وسائل الإعلام المختلفة وكل ما هو مرتبط بها. ورغم أن تخصص الإعلام والتواصل الجماهيري يقدّم العديد من الدورات والبرامج الدراسية إلا أن معظمها تركز بشكل أساسي على التحضير العملي لمختلف المهن الإعلامية وتزويد الطلاب ببعض المهارات العلمية مثل التصوير، الكتابة الصحفية، والتسويق الإلكتروني.",
  Mathematics:
    "يعد تخصص الرياضيات من التخصصات المهمة؛ كونه يشمل مجالات كثيرة، ويعرف علم الرياضيات بأنه علم دراسة البنى المجردة باستخدام المنطق والبراهين الرياضية والتدوين الرياضي، وهو العمود الفقري لمعظم العلوم الحديثة التي ساهمت في تطور البشرية على مدى القرون الماضية خاصة في الكيمياء والفيزياء والهندسة.",
  Medicine:
    "هو التخصص المعني بتشخيص الحالات المرضية، وتوفير العلاج بالدواء أو الجراحة. فضلًا عن الوقاية من الأمراض بالكثير من الطرق الطبية. ويهتم بدراسة الأمراض المتنوعة التي تصيب جسم الإنسان والتعرف على المشاكل التي تصيب أو تؤثر على صحته.",
  Music:
    "يعرف علم الموسيقى بأنه العلم الذي يدرس الموسيقى على المستوى الأكاديمي العالي ولها مجالات مختلفة تبدأ بالمجال الضيق الذي يعتمد على دراسة تاريخ الموسيقى في الثقافات مروراً بمجالها المتوسط الذي يشمل جميع الثقافات ذات الصلة ومجموعة التقاليد والأشكال الموسيقية وانتهاءً بالمجال الواسع الذي يشمل جميع التخصصات ذات الصلة الموسيقية في جميع الثقافات حيث يتم إجراء البحوث على العديد من المستويات ويكون الطالب قادراً على التخصص في المنطقة التي هي في مصلحته وتعبر عن موهبته.",
  Nursing:
    "هو أحد التخصصات الطبية التي تعتني بصحة المريض والتخفيف من ألمه، ويعتبر التمريض أيضاً من التخصصات المساعدة لمهنة الطب، حيث يساعد أخصائي التمريض الطبيب في تقديم وسائل العلاج للمريض، مثل: الإبر، والأدوية، إلى جانب مراقبة حالته الصحية، ومد الطبيب بالكشوفات الطبية اللازمة، والملف الصحي لكل مريض.",
  Nutrition:
    "هو التخصص الذي يدرس علاقة الغذاء والطعام بالإنسان والكائنات الحية جميعها، أي أنه يدرس كيف يؤثر الغذاء على جسم الإنسان. يعثبر أخصائي التغذية من أحد أعضاء الفريق الصحي في المستشفيات، ومراكز ومؤسسات الرعاية الصحية.",
  Pharmacy:
    "يقصد بالصيدلة أنها تقديم العلاج، والأدوية، والمساعدة الطبية اللازمة للمرضى، حيث يشمل أيضًا مجال الصيدلة إدارة الصيدليات، والعيادات، والمؤسسات الطبية، والمستشفيات. وهو تخصص طبي، وصحي، وظيفته ربط الصحة بعلم الصيدلة، كما يهدِف إلى ضمان، وتوفير، وتأمين استخدام الأدوية استخدامًا آمنًا، وصحيًا، ومناسبًا، وفعالًا. ",
  Physics:
    "يعد الفيزياء علمًا طبيعيًا يعتمد على التحليل الرياضي وإجراء التجارب، لمحاولة إيجاد قوانين تفسر الظواهر من حولنا ابتداءً بالأشياء الصغيرة والتفاصيل البسيطة وصولًا إلى الكواكب والأنظمة الشمسية.",
  Physiotherapy:
    "يعرف العلاج الفيزيائي أو الطبيعي أنه ممارسة طبية، وحركات، وتمارين عندما تكون حركة جسم الإنسان مهددة أو في خطر لأسباب كثيرة سواءًا كانت بسبب كبر السن، أو التعرض لإصابة، أو الأمراض، أو بسبب العوامل البيئية المحيطة. يهدف إلى التحسين، والتطوير من مجالات الحياة عبر الوقاية، والعلاج، والتأهيل؛ حيث يكون العلاج الفيزيائي طريقة تفاعل بين الأطباء والمرضى تعتمد على تقييم الأطباء لحركة المرضى، ومن ثم تشخيص الحالة للبدء بخطة العلاج.",
  "Political Science":
    "يتناول تخصص العلوم السياسية التطور، والوظائف الخاصة بالحكومات، وسلوك الحكام على مر التاريخ، حيث إن العديد من خريجي العلوم السياسية يتابعون وظائفهم في الحكومة أو الخدمة العامة، كسياسيين منتخبين، أو موظفين مساعدين، أو محللين استشاريين.",
  Psychology:
    "يقصد بعلم النفس أنه العلم الذي يدرس عقل الإنسان، وهو أيضًا الدراسة العلمية لسلوك الإنسان، والقدرة على التنبؤ به. كما يعرف علم النفس أنه البرنامج الذي يركز على الدراسة العلمية للسلوك الفردي والجماعي، والقواعد الفيزيائية، والبيئية للسلوك. يعرف علم النفس أيضًا على أنه العلم الذي يشمل تحليل، ودراسة العقل، والشخصية، وطريقة التفكير لدى الأشخاص وبالتالي، إمكانية توقع تصرفاتهم، وردود أفعالهم بناءً على ذلك.",
  Radiography:
    "تخصص الأشعة يكون عادة قسم من ضمن أقسام كلية العلوم الطبية التطبيقية. الأشعة لها إستخدامات عديدة في الطب سوءاً على صعيد التشخيص أو العلاج. تساهم الأشعة بشكل كبير في تطور الطب والحفاظ على صحة المرضى والأشعة هي ظاهرة طبيعية تم إستغلالها في الطب إذ أن الإشعاعات تحيط بنا طوال الوقت. هناك الإشعاعات الكونية الآتية من الفضاء والكواكب العديدة، هناك أيضاً إشعاعات صادرة من الأرض والتربة كغاز الرادون.",
  "Renewable & Sustainable Energy Engineering":
    "يقوم هذا التخصص بدراسة كل ما يتعلق بالطاقة المستدامة مثل توليدها، ونقلها، واستخدامها، والاستفادة منها، حيث أصبحت الطاقة المستدامة من المجالات الدراسية وذلك نظرًا لحاجتنا الشديدة لها.",
  Sociology:
    "تخصص علم الإجتماع يركز على دراسة جوانب مختلفة من المجتمع ، بدءاً من التعاملات المختلفة بين البشر ، انتهاءاً بالبحث عن الكيفية التى تكون بها المجتمع ذاته، ويتطرق هذا العلم الى العديد من الدراسات الاكاديمية المرتبطة به ، مثل علوم النفس والتاريخ والعلوم الانسانية وعلم السلوك الانساني.",
  Education:
    "هو تخصص يهدف للبحث عن الطرق المثلى لتعلم الناس وكيف يمكن أن يصبح الشخص معلما أفضل. ويساعد دارسيه أيضا على تعلم كيفية تحضير خطط دراسية وكيفية قياس مدى استفادة طلابهم.",
  Statistics:
    "هو تخصص يهتم بجمع البيانات الرقمية ومن ثم تنظيمها وتحليلها، للوصول إلى نتائج معينة بهدف توضيح ظاهرة أو حالة ما ويستخدم علم الاحصاء في عدة مجالات  كالعلوم الطبية، وعلم الاجتماع، والاقتصاد، والصناعة، والكيمياء، والرياضة، والإدارة وغيرها وتعتمد الدول عادة على خبراء الاحصاء بشكل أساسي لمعرفة عدد السكان أو عدد المصابين بمرض ما وغيرها من التقارير المهمة.",
  "Theatre and Dance":
    "هو تخصص يهتم بتعليم الموهوبين أسس الفنون الجميلة وتاريخها. ويهدف إلى إيصال طلبته إلى مستوى متقدم ومحترف.",
  "Veterinary Medicine":
    "هو تخصص يهدف إلى تزويد طلبته بالمعرفة اللازمة التي تتعلق بالحيوانات وكيفية تربيتهم والحفاظ على صحتهم. ويهتم هذا المجال بتشخيص الاامراض والإضطارابات التي تتعرض لها الحيوانات وطرق علاجها والأدوية المناسبة.",
  Law: "القانون علم من العلوم الاجتماعية المختص بدراسة مجموعة من القواعد والقوانين العامة، والتي تعمل بدورها على تنظيم العلاقات الاجتماعية بين أفراد المجتمع، على أن يلتزم بها كافة أفراد المجتمع دون تفريق بينهم.",
  "Agricultural science":
    "العلوم الزراعية هي مجموعة من الانشطة التى تهدف بشكل عام تحويل المعطيات الطبيعية الى انتاج حيوانى ونباتي قابل للإستخدام البشري ، عن طريق استخدام التقنيات والتطبيقات الزراعية المختلفة.",
  "Mechanical Engineering":
    "هي فرع من فروع الهندسة التي تهتم بطرق تصميم وتشغيل وتطوير الالات والاجهزة.",
};

export const questions = [
  {
    position: 1,
    Question: "I need the freedom to be creative and express myself.",
    Question_ar: "أحب الإبداع والتعبيرعن نفسي بحرية",
    image: "https://cdn.ulearn.study/courses/major-images/Q1-min.png",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 1,
      "Art & Design / Interior Design": 1,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 1,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 3,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 1,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 2,
    image: "https://cdn.ulearn.study/courses/major-images/Q2-min.png",
    Question:
      "I enjoy theatre arts, either performing as an actor or working behind the scenes on a production.",
    Question_ar:
      "أحب الفنون المسرحية سواء أن أؤدي فيها كممثل أو أن اعمل وراء الكواليس",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 1,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 1,
      Mathematics: 0,
      Medicine: 0,
      Music: 1,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 4,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 3,
    image: "https://cdn.ulearn.study/courses/major-images/Q3-min.png",
    Question: "I like to give advice.",
    Question_ar: "أحب إعطاء النصائح والتوجيهات",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 1,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 1,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 1,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 1,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 4,
    image: "https://cdn.ulearn.study/courses/major-images/Q4-min.png",
    Question: "I am interested in rehabilitating and healing people.",
    Question_ar: "أنا مهتم بإعادة التأهيل ومعالجة الناس",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 2,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 2,
      Music: 0,
      Nursing: 3,
      Nutrition: 1,
      Pharmacy: 2,
      Physics: 0,
      Physiotherapy: 2,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 2,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 1,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 5,
    image: "https://cdn.ulearn.study/courses/major-images/Q5-min.png",
    Question:
      "I am very ambitious, persuasive and love coming up with new ideas.",
    Question_ar: "أنا طموح جداً، مقنع وأحب أن أقدم أفكار جديدة",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 3,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 3,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 1,
      "International Relations": 1,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 3,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 1,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 6,
    image: "https://cdn.ulearn.study/courses/major-images/Q6-min.png",
    Question:
      "I care about the state of the environment and want to help improve it in my career.",
    Question_ar: "تهمني الطبيعة جداً وأريد أن اعمل على تحسينها في مهنتي",

    answer: {
      Accounting: 0,
      Biology: 1,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 2,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 2,
      Finance: 0,
      Geology: 2,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 2,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 2,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 7,
    image: "https://cdn.ulearn.study/courses/major-images/Q7-min.png",
    Question: "I enjoy learning about different parts of the world.",
    Question_ar: "أستمتع بالتعلم عن المناطق المختلفة في العالم",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 2,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 1,
      "Graphic Design": 0,
      History: 1,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 1,
      "International Relations": 2,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 1,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 1,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 2,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 8,
    image: "https://cdn.ulearn.study/courses/major-images/Q8-min.png",
    Question: "I like math and figuring out how things work.",
    Question_ar: "أحب الرياضيات ومعرفة كيف تعمل الأشياء",

    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 1,
      "Civil Engineering": 2,
      "Computer Science": 1,
      "Construction Management": 1,
      Economics: 0,
      "Electrical Engineering": 1,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 1,
      "Aeronautical Engineering": 1,
      "Architectural Engineering": 1,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 1,
      "Cyber Security": 1,
      "Data Science": 1,
      Dentistry: 0,
      "Electronic Engineering": 1,
      Entrepreneurship: 0,
      "Environmental Engineering": 1,
      Finance: 1,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 1,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 2,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 3,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 1,
      Sociology: 0,
      Education: 0,
      Statistics: 1,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 3,
    },
  },
  {
    position: 9,
    image: "https://cdn.ulearn.study/courses/major-images/Q9-min.png",
    Question: "I'm interested in graphic design and/or web design.",
    Question_ar: "أنا مهتم بتصميم الجرافيك أو تصميم المواقع",

    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 1,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 1,
      Architecture: 1,
      "Art & Design / Interior Design": 2,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 1,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 3,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 1,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 10,
    image: "https://cdn.ulearn.study/courses/major-images/Q10-min.png",
    Question: "I'm interested in technology and learning how computers work.",
    Question_ar: "أنا مهتم بالتكنولوجيا ومعرفة كيف تعمل الحواسيب",

    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 1,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 1,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 1,
      "Architectural Engineering": 1,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 1,
      "Cyber Security": 1,
      "Data Science": 1,
      Dentistry: 0,
      "Electronic Engineering": 1,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 1,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 1,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 2,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 1,
    },
  },
  {
    position: 11,
    image: "https://cdn.ulearn.study/courses/major-images/Q11-min.png",
    Question: "I enjoy learning about the human body and the natural world.",
    Question_ar: "أستمتع بالتعلم عن جسم الإنسان والبيئة والطبيعة",

    answer: {
      Accounting: 0,
      Biology: 4,
      Chemistry: 2,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 1,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 1,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 1,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 2,
      Music: 0,
      Nursing: 2,
      Nutrition: 0,
      Pharmacy: 2,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 1,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 1,
      Law: 0,
      "Agricultural science": 1,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 12,
    image: "https://cdn.ulearn.study/courses/major-images/Q12-min.png",
    Question: "I like to work with my hands.",
    Question_ar: "أحب أن اعمل باستخدام يداي",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 1,
      "Civil Engineering": 2,
      "Computer Science": 0,
      "Construction Management": 2,
      Economics: 0,
      "Electrical Engineering": 1,
      "English Literature": 0,
      "Environmental Science": 2,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 1,
      "Architectural Engineering": 1,
      Architecture: 1,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 2,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 1,
      Finance: 0,
      Geology: 1,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 2,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 1,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 2,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 1,
      "Mechanical Engineering": 3,
    },
  },
  {
    position: 13,
    image: "https://cdn.ulearn.study/courses/major-images/Q13-min.png",
    Question: "I have a strong interest and ability in visual arts.",
    Question_ar: "لدي شغف كبير وقدرة عالية في الفنون البصرية",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 2,
      "Art & Design / Interior Design": 2,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 2,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 14,
    image: "https://cdn.ulearn.study/courses/major-images/Q14-min.png",
    Question:
      "I work well with others, and I have strong written and verbal communication skills.",
    Question_ar: "اعمل جيداً مع الآخرين ولدي مهارات تواصل قوية",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 1,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 2,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 1,
      "Communication - Journalism": 2,
      "Communication & Media Studies": 2,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 2,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 1,
      "Human Resources Management": 2,
      "Industrial Engineering": 0,
      "International Business": 1,
      "International Relations": 1,
      Linguistics: 1,
      "Management Information Systems": 0,
      Marketing: 3,
      "Mass Communications": 2,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 3,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 1,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 3,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 15,
    image: "https://cdn.ulearn.study/courses/major-images/Q15-min.png",
    Question: "I'm good with numbers and am detail-oriented.",
    Question_ar: "أنا جيد مع الأرقام وأهتم بالتفاصيل",
    answer: {
      Accounting: 4,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 2,
      "Construction Management": 0,
      Economics: 2,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 2,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 1,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 1,
      "Cyber Security": 1,
      "Data Science": 2,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 2,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 1,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 1,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 3,
      Medicine: 0,
      Music: 0,
      Nursing: 1,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 2,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 16,
    image: "https://cdn.ulearn.study/courses/major-images/Q16-min.png",
    Question: "I'm interested in law, debate, government and politics.",
    Question_ar: "أنا مهتم بالقانون، المناظرات، السياسة والحكومة",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 1,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 1,
      "Communication & Media Studies": 1,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 1,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 1,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 4,
      Psychology: 3,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 2,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 4,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 17,
    image: "https://cdn.ulearn.study/courses/major-images/Q17-min.png",
    Question:
      "I would like to work with conflict resolution, mediation or criminal justice.",
    Question_ar: "أحب أن اعمل في حل النزاعات أو العدالة الجنائية",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 1,
      "Human Resources Management": 2,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 1,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 2,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 1,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 1,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 18,
    image: "https://cdn.ulearn.study/courses/major-images/Q18-min.png",
    Question: "I am always reading a book or writing my own stories.",
    Question_ar: "دائما ما أقرأ كتاب أو اكتب قصصي الخاصة",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 2,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 2,
      "Communication & Media Studies": 1,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 1,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 1,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 1,
      Statistics: 0,
      "Theatre and Dance": 1,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 19,
    image: "https://cdn.ulearn.study/courses/major-images/Q19-min.png",
    Question:
      "I like to experiment with better and faster ways of doing things.",
    Question_ar: "أحب دائما أن اختبر طرق جديدة لإنجاز المهام أسرع وأفضل",
    answer: {
      Accounting: 0,
      Biology: 1,
      Chemistry: 1,
      "Chemical Engineering": 2,
      "Civil Engineering": 1,
      "Computer Science": 2,
      "Construction Management": 1,
      Economics: 0,
      "Electrical Engineering": 1,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 1,
      "Aeronautical Engineering": 1,
      "Architectural Engineering": 1,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 2,
      "Cyber Security": 1,
      "Data Science": 1,
      Dentistry: 0,
      "Electronic Engineering": 1,
      Entrepreneurship: 0,
      "Environmental Engineering": 1,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 2,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 1,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 2,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 1,
      Sociology: 0,
      Education: 0,
      Statistics: 1,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 1,
    },
  },
  {
    position: 20,
    image: "https://cdn.ulearn.study/courses/major-images/Q20-min.png",
    Question: "I am patient and enjoy working with children.",
    Question_ar: "أنا صبور واستمتع بالعمل مع الأطفال",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 1,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 1,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 21,
    image: "https://cdn.ulearn.study/courses/major-images/Q21-min.png",
    Question: "I'm interested in science, and I want to help people.",
    Question_ar: "أنا مهتم بالعلوم وأريد مساعدة الناس",
    answer: {
      Accounting: 0,
      Biology: 1,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 1,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 1,
      Dentistry: 2,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 1,
      Finance: 0,
      Geology: 1,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 3,
      Music: 0,
      Nursing: 3,
      Nutrition: 2,
      Pharmacy: 3,
      Physics: 0,
      Physiotherapy: 2,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 1,
      "Renewable & Sustainable Energy Engineering": 1,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 1,
      Law: 0,
      "Agricultural science": 1,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 22,
    image: "https://cdn.ulearn.study/courses/major-images/Q22-min.png",
    Question: "I love learning new languages.",
    Question_ar: "أحب تعلم لغات جديدة",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 1,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 1,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 3,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 23,
    image: "https://cdn.ulearn.study/courses/major-images/Q23-min.png",
    Question: "I enjoy helping people.",
    Question_ar: "أحب مساعدة الناس",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 2,
      "Communication & Media Studies": 1,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 1,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 1,
      Mathematics: 0,
      Medicine: 2,
      Music: 0,
      Nursing: 3,
      Nutrition: 2,
      Pharmacy: 2,
      Physics: 0,
      Physiotherapy: 2,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 1,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 2,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 24,
    image: "https://cdn.ulearn.study/courses/major-images/Q24-min.png",
    Question:
      "I want to work with people who have been oppressed or on the margins of society.",
    Question_ar: "أريد أن اعمل مع الأقليات أو الأشخاص المعنفين",

    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 2,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 25,
    image: "https://cdn.ulearn.study/courses/major-images/Q25-min.png",
    Question: "I'm interested in a wide range of subjects and careers.",
    Question_ar: "أنا مهتم بعدة مجالات ووظائف",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 2,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 26,
    image: "https://cdn.ulearn.study/courses/major-images/Q26-min.png",
    Question: "I'm interested in intellectual ideas and philosophy.",
    Question_ar: "أنا مهتم بالأفكار العميقة والفلسفة",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 2,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 1,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 1,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 1,
      Psychology: 1,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 1,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 1,
      "Veterinary Medicine": 0,
      Law: 1,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 27,
    image: "https://cdn.ulearn.study/courses/major-images/Q27-min.png",
    Question: "I like to sing and/or play musical instruments.",
    Question_ar: "أحب الغناء أو لعب الآلات الموسيقية",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 3,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 1,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 28,
    image: "https://cdn.ulearn.study/courses/major-images/Q28-min.png",
    Question: "I'm good at organizing and like to be in charge.",
    Question_ar: "أنا جيد بالتنظيم والإدارة",
    answer: {
      Accounting: 1,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 1,
      "Computer Science": 0,
      "Construction Management": 1,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 2,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 2,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 1,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 1,
      Marketing: 1,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 29,
    image: "https://cdn.ulearn.study/courses/major-images/Q29-min.png",
    Question: "I 'd love to learn about coding",
    Question_ar: "أحب تعلم البرمجة ولغاتها",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 3,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 1,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 2,
      "Cyber Security": 3,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 0,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
  {
    position: 30,
    image: "https://cdn.ulearn.study/courses/major-images/Q30-min.png",
    Question:
      "I'm interested in all animals and want to help them in my career.",
    Question_ar: "أحب جميع الحيوانات وأريد مساعدتهم في مهنتي",
    answer: {
      Accounting: 0,
      Biology: 0,
      Chemistry: 0,
      "Chemical Engineering": 0,
      "Civil Engineering": 0,
      "Computer Science": 0,
      "Construction Management": 0,
      Economics: 0,
      "Electrical Engineering": 0,
      "English Literature": 0,
      "Environmental Science": 0,
      "Actuarial Science": 0,
      "Aeronautical Engineering": 0,
      "Architectural Engineering": 0,
      Architecture: 0,
      "Art & Design / Interior Design": 0,
      "Business Mangment": 0,
      "Communication - Journalism": 0,
      "Communication & Media Studies": 0,
      "Computer Engineering": 0,
      "Cyber Security": 0,
      "Data Science": 0,
      Dentistry: 0,
      "Electronic Engineering": 0,
      Entrepreneurship: 0,
      "Environmental Engineering": 0,
      Finance: 0,
      Geology: 0,
      "Graphic Design": 0,
      History: 0,
      "Hospitality Administration": 0,
      "Human Resources Management": 0,
      "Industrial Engineering": 0,
      "International Business": 0,
      "International Relations": 0,
      Linguistics: 0,
      "Management Information Systems": 0,
      Marketing: 0,
      "Mass Communications": 0,
      Mathematics: 0,
      Medicine: 0,
      Music: 0,
      Nursing: 0,
      Nutrition: 0,
      Pharmacy: 0,
      Physics: 0,
      Physiotherapy: 0,
      "Political Science": 0,
      Psychology: 0,
      Radiography: 0,
      "Renewable & Sustainable Energy Engineering": 0,
      Sociology: 0,
      Education: 0,
      Statistics: 0,
      "Theatre and Dance": 0,
      "Veterinary Medicine": 3,
      Law: 0,
      "Agricultural science": 0,
      "Mechanical Engineering": 0,
    },
  },
];
