import React from "react";
import DayPicker from "react-day-picker";
import { Button } from "react-bootstrap";

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear - 50, 50);

function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();
  const years = [];
  for (let i = fromMonth.getFullYear(); i >= toMonth.getFullYear(); i -= 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption" style={{ zIndex: 2 }}>
      <div className="d-flex flex-row justify-content-between">
        <select
          name="month"
          className="custom-calender"
          onChange={handleChange}
          value={date.getMonth()}
          style={{ width: "100%" }}
        >
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select
          name="year"
          className="custom-calender"
          onChange={handleChange}
          value={date.getFullYear()}
          style={{
            width: "100%",
            borderLeft: "1px solid #e8e9f5",
            margin: "-17px 0",
          }}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </form>
  );
}

export default class DobCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.state = {
      month: fromMonth,
      selectedDate: this.props.dob,
    };
  }

  handleYearMonthChange(month) {
    this.setState({ month });
  }

  render() {
    // console.log.*$
    return (
      <div style={{ position: "relative", width: "fit-content" }}>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 18,
            zIndex: 2,
          }}
          onClick={() => this.props.onAdd()}
        >
          <span>X</span>
        </div>

        <DayPicker
          month={this.state.month}
          fromMonth={fromMonth}
          toMonth={toMonth}
          selectedDays={this.state.selectedDate}
          onDayClick={async (date) => {
            this.setState({ selectedDate: date });
            this.props.selectedDate(date);
            this.props.onAdd();
          }}
          captionElement={({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onChange={this.handleYearMonthChange}
            />
          )}
        />
        <div className="w-100 d-flex justify-content-center">
          <Button
            onClick={() => this.props.onAdd()}
            className="cal-add-btn d-flex justify-content-center"
          >
            Add
          </Button>
        </div>
      </div>
    );
  }
}
