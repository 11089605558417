import React from "react";

function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#1E2432"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 14.667A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334z"
      ></path>
      <path
        stroke="#1E2432"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 4v4l2.667 1.333"
      ></path>
    </svg>
  );
}

export default ClockIcon;
