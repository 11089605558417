/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";
import { Con, TextCon, HeaderText, Subtext } from "./styled/home";
import { IndexIcon } from "../Assets/Images/IndexIcon";
import { CircleCheck } from "../Assets/Images/CircleCheck";
import { ButtonComponent } from "../Component/ButtonComponent";
import { ModalComponent } from "../Component/Modal";
import { IndexIconEn } from "../Assets/Images/IndexIconEn";
import Header from "../Component/Header/Header";

export const Apply = () => {
  const { i18n, t } = useTranslation();
  const [show, setshow] = React.useState(false);

  // const loacation = useLocation();
  // const navigate = useNavigate();

  const [lang, setlang] = React.useState(i18n.language);
  const [, setshowLang] = React.useState(false);

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
    // i18n.changeLanguage(lang);
  }, []);

  const arabic = lang === "ar";

  const changeLanguageHandler = (lang) => {
    setlang(lang);
    i18n.changeLanguage(lang);
    setshowLang(false);
  };
  // console.log(loacation.pathname);
  // // if (loacation.pathname.includes("/apply/ar")) {
  // //   return <Navigate to={`/apply`} />;
  // // }
  // console.log(window.location.search);
  return (
    <>
      <Header
        onBoarding={
          <ButtonComponent
            style={{ width: 100, zIndex: 999 }}
            width="100%"
            langBtn={true}
            label={lang === "ar" ? "En" : "Ar"}
            loading={false}
            btnType={"dark"}
            onClick={() => {
              changeLanguageHandler(lang === "ar" ? "en" : "ar");
            }}
          />
        }
      />
      <Con>
        {!arabic ? (
          <div
            className="pc-view"
            style={{
              position: "absolute",
              right: 0,
              bottom: "-34px",
              // zIndex: 99,
            }}
          >
            <IndexIconEn />
          </div>
        ) : (
          <div
            className="pc-view"
            style={{
              position: "absolute",
              left: 0,
              bottom: "-34px",
            }}
          >
            <IndexIcon />
          </div>
        )}

        <TextCon
          direction={arabic ? "flex-end" : "flex-start"}
          className="container"
        >
          <HeaderText arabic={arabic}>{t("common:homeHeader1")}</HeaderText>
          <HeaderText arabic={arabic}>{t("common:homeHeader2")}</HeaderText>
          <div
            style={{ marginTop: "30px" }}
            className={`d-flex flex-column ${
              arabic ? "align-items-end" : "align-items-start"
            }`}
          >
            <div>
              {" "}
              <Subtext fontWeight={700} arabic={arabic}>
                {t("common:applySteps")}
              </Subtext>
            </div>
            <div
              className={`flex-column container d-flex justify-content-center ${
                arabic ? "align-items-end" : "align-items-start"
              } h-100`}
            >
              <div
                className={`d-flex align-items-center ${
                  arabic ? "flex-row" : "flex-row-reverse"
                }`}
                style={{ marginTop: 6 }}
              >
                <Subtext fontWeight={500} arabic={arabic}>
                  {t("common:applyStep1")}
                </Subtext>
                <CircleCheck
                  width={22}
                  height={22}
                  style={{ margin: !arabic ? "0 10px 0 0" : "0 0 0 10px" }}
                />
              </div>
              <div
                className={`d-flex align-items-center ${
                  arabic ? "flex-row" : "flex-row-reverse"
                }`}
              >
                <Subtext fontWeight={500} arabic={arabic}>
                  {t("common:applyStep2")}
                </Subtext>
                <CircleCheck
                  width={22}
                  height={22}
                  style={{ margin: !arabic ? "0 10px 0 0" : "0 0 0 10px" }}
                />
              </div>
              <div
                className={`d-flex align-items-center ${
                  arabic ? "flex-row" : "flex-row-reverse"
                }`}
              >
                <Subtext fontWeight={500} arabic={arabic}>
                  {t("common:applyStep3")}
                </Subtext>
                <CircleCheck
                  width={22}
                  height={22}
                  style={{ margin: !arabic ? "0 10px 0 0" : "0 0 0 10px" }}
                />
              </div>
              <ButtonComponent
                style={{
                  marginTop: 29,
                  padding: "0 58px",
                  width: "90%",
                  zIndex: 99,
                  marginBottom: 30,
                }}
                width="100%"
                className="pc-view"
                label={t("common:getStarted")}
                loading={false}
                btnType={"dark"}
                onClick={() => setshow(true)}
              />
            </div>
          </div>
          <ButtonComponent
            style={{
              marginTop: 29,
              padding: "0 58px",
              width: "90%",
              zIndex: 99,
              marginBottom: 30,
            }}
            width="100%"
            className="res-home-btn mobile-view"
            label={t("common:getStarted")}
            loading={false}
            btnType={"dark"}
            onClick={() => setshow(true)}
          />
        </TextCon>
        <div
          className="mobile-view"
          style={{ position: "relative", bottom: 0, zIndex: 99 }}
        >
          {/* <IndexIconMobile /> */}
          <img
            style={{ width: "100%" }}
            src={
              arabic
                ? "https://storage.googleapis.com/uapply/ulearnabroad-apply-bnr.png"
                : "https://storage.googleapis.com/uapply/ulearnabroad-apply-bnr.png"
            }
            alt="img"
          />
        </div>
        {show ? (
          <ModalComponent show={show} onHide={() => setshow(!show)} />
        ) : null}
      </Con>
    </>
  );
};
