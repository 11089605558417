import React from "react";

function BottomLine() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="266"
      height="5"
      fill="none"
      className="bottom-line"
      viewBox="0 0 266 5"
    >
      <path
        fill="#FD3E60"
        d="M.395 1.83L197.252.445c21.875.106 43.746-.805 65.617-.284 1.786.045 3.188 1.137 3.129 2.438-.055 1.25-1.449 2.24-3.142 2.278-21.874.456-43.749-.517-65.62-.482L.392 2.42c-.22-.002-.398-.134-.392-.302.007-.156.181-.28.395-.288z"
      ></path>
    </svg>
  );
}

export default BottomLine;
