export const config = {
  url: {
    API_URL: process.env.REACT_APP_API_ENDPOINT,
    API_URL_UAPPLY: process.env.REACT_APP_API_ENDPOINT_UAPPLY,
  },
};

console.log(config.url.API_URL_UAPPLY);

export const SAVE_MAJOR = `${config.url.API_URL}/majors/saveMajor`;
export const MAJOR = `${config.url.API_URL}/majors`;

export const SEARCH_SUBJECT = `${config.url.API_URL_UAPPLY}/subjects/search`;
export const SEARCH_COUNTRY = `${config.url.API_URL_UAPPLY}/countries/search`;
export const LEADS = `${config.url.API_URL_UAPPLY}/leads`;
export const UNIVERSITIES = `${config.url.API_URL_UAPPLY}/universities`;
