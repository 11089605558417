import React from "react";

function Instagram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "32"}
      height={props.height ? props.height : "32"}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={props.color}
        d="M27.911 11.07a8.424 8.424 0 00-.576-2.879 6.229 6.229 0 00-3.533-3.533 8.426 8.426 0 00-2.879-.576c-1.32-.081-1.725-.081-4.93-.081-3.206 0-3.616 0-4.93.081a8.425 8.425 0 00-2.88.576 6.228 6.228 0 00-3.526 3.537 8.425 8.425 0 00-.575 2.879C4 12.304 4 12.714 4 16s0 3.615.082 4.93c.022.986.216 1.96.575 2.88a6.229 6.229 0 003.533 3.532 8.426 8.426 0 002.88.576c1.232.082 1.643.082 4.93.082s3.616 0 4.93-.082a8.427 8.427 0 002.88-.576 6.227 6.227 0 003.533-3.533 8.423 8.423 0 00.575-2.879C28 19.7 28 19.288 28 16.001s0-3.616-.082-4.93h-.007zm-2.136 9.777a7.482 7.482 0 01-.41 2.218 3.973 3.973 0 01-2.301 2.301 7.482 7.482 0 01-2.219.41c-1.232.082-1.643.082-4.847.082-3.205 0-3.533 0-4.848-.081a7.482 7.482 0 01-2.218-.41 3.972 3.972 0 01-2.302-2.3 7.481 7.481 0 01-.412-2.22c-.081-1.232-.081-1.643-.081-4.848 0-3.204 0-3.532.081-4.847a7.48 7.48 0 01.41-2.218 3.977 3.977 0 012.303-2.3 7.481 7.481 0 012.218-.41c1.32-.082 1.643-.082 4.847-.082 3.205 0 3.533 0 4.848.082a7.481 7.481 0 012.218.41 3.97 3.97 0 012.301 2.3c.249.715.387 1.463.41 2.219.083 1.232.083 1.643.083 4.848 0 3.204 0 3.533-.081 4.846z"
      ></path>
      <path
        fill={props.color}
        d="M15.894 9.638a6.385 6.385 0 106.385 6.384 6.37 6.37 0 00-6.385-6.384zm0 10.525a4.142 4.142 0 01-4.14-4.14 4.141 4.141 0 118.277 0 4.142 4.142 0 01-4.137 4.14zm6.729-12.25a1.512 1.512 0 00-1.466 1.466 1.467 1.467 0 002.933 0 1.402 1.402 0 00-1.467-1.468v.002z"
      ></path>
    </svg>
  );
}

export default Instagram;
