import * as React from "react";

import { Button } from "./styled";
import Loader from "../Loader/Loader";

export const ButtonComponent = (props) => {
  return (
    <Button
      style={props.style}
      langBtn={props.langBtn}
      width={props.width}
      disabled={props.loading || props.disableBtnStyle}
      className={props.className}
      btnType={props.btnType}
      type="submit"
      loading={props.loading ? 1 : 0}
      onClick={(e) => props.onClick?.(e)}
      disableBtnStyle={props.disableBtnStyle}
    >
      {props.loading ? <Loader /> : props.label}
    </Button>
  );
};
