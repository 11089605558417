/* eslint-disable import/no-anonymous-default-export */
import { Action, LANG, SHOW, SHOW_MAJOR } from "../actions";

const INTIAL_STATE = {
  lang: "ar",
  show: false,
  showMajor: false,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case SHOW:
      return {
        ...state,
        show: action.payload,
      };
    case SHOW_MAJOR:
      return {
        ...state,
        showMajor: action.payload,
      };

    default:
      return state;
  }
};
