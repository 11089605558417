import React from "react";
import { Col, Container } from "react-bootstrap";
import Facebook from "../../Assets/Images/Facebook";
import Instagram from "../../Assets/Images/Instagram";
import LinkedIn from "../../Assets/Images/LinkedIn";
import Logo from "../../Assets/Images/Logo";
import Twitter from "../../Assets/Images/Twitter";
import Youtube from "../../Assets/Images/Youtube";
import styled from "styled-components";
import { PopupButton } from "react-calendly";

export default function Footer(props) {
  const courses = [
    { ar: "الانجليزي", en: "english" },
    { ar: "الفرنسي", en: "french" },
    { ar: "الاسباني", en: "spanish" },
    { ar: "الإيطالي", en: "italian" },
    { ar: "آيلتس", en: "ielts" },
  ];

  return (
    <div>
      {" "}
      <div>
        <Container>
          <>
            <div className={"footer-con"}>
              <Col md={3}>
                <div className={"d-flex flex-column align-items-end"}>
                  <a href={`/kw`}>
                    <Logo />
                  </a>
                  <SubText className="footer-subtext">
                    للدراسة في الخارج
                  </SubText>
                  <SubText className="footer-subtext">
                    شرق، قطعة 6، شارع أحمد الجابربرج سيتي تاور، الطابق العاشر
                  </SubText>
                  <div
                    className={"mobile-view"}
                    style={{ padding: "20px 0 40px 0" }}
                  >
                    <a
                      href="https://www.linkedin.com/company/ulearn-for-academic-services"
                      target="_blank"
                      style={{
                        paddingRight: 25,
                        textDecoration: "none",
                      }}
                      rel="noreferrer"
                    >
                      <LinkedIn color="#1E2432" width={24} height={24} />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCj6FYNv9hLPp6CJKyl0LSuw/featured?view_as=subscriber"
                      style={{
                        paddingRight: 25,
                        textDecoration: "none",
                      }}
                    >
                      <Youtube color="#1E2432" width={24} height={24} />
                    </a>
                    <a
                      href="https://www.instagram.com/ulearnabroad/"
                      style={{
                        paddingRight: 25,
                        textDecoration: "none",
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Instagram color="#1E2432" width={24} height={24} />
                    </a>

                    <a
                      href="https://twitter.com/ulearnstudy"
                      style={{
                        paddingRight: 25,
                        textDecoration: "none",
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Twitter color="#1E2432" width={24} height={24} />
                    </a>
                    <a
                      href="https://www.facebook.com/Ulearnabroad"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Facebook color="#1E2432" width={24} height={24} />
                    </a>
                  </div>
                </div>
              </Col>
              <Col md={9} style={{ paddingBottom: 30 }}>
                <div className={"d-flex flex-row-reverse res-column-footer"}>
                  <Col
                    md={4}
                    style={{
                      paddingRight: 0,
                    }}
                    className="footer-section-end"
                  >
                    <div className="d-flex flex-column">
                      <Header
                        style={{
                          textAlign: "right",
                        }}
                      >
                        الشركة
                      </Header>
                      {/* <SubText
                                    style={{
                                        textAlign: t("common:arabic")
                                            ? "right"
                                            : "left",
                                    }}
                                >
                                    {t("common:footerCol2About")}
                                </SubText> */}
                      <SubText
                        style={{
                          textAlign: "right",
                        }}
                        target="_blank"
                        href={`https://goo.gl/maps/EEmJyx4yArYLqeWV6`}
                      >
                        لوكيشن المكتب
                      </SubText>

                      <SubText
                        style={{
                          textAlign: "right",
                        }}
                        // href={
                        //   router.pathname === "/pricing"
                        //     ? "/pricing/#faq"
                        //     : "/pricing/#faq"
                        // }
                      >
                        <PopupButton
                          className="footer-popup"
                          url="https://calendly.com/ulearn/website?hide_gdpr_banner=1"
                          utm={{
                            utmCampaign: props.utm_campaign,
                            utmContent: props.utm_content,
                            utmMedium: props.utm_medium,
                            utmSource: props.utm_source,
                            utm_term: props.utm_term,
                          }}
                          rootElement={document.getElementById("root")}
                          text=" احجز استشارة"
                        />
                      </SubText>
                      <SubText
                        style={{
                          textAlign: "right",
                        }}
                        target="_blank"
                        href="https://wa.me/96566687847"
                      >
                        تواصل معنا
                      </SubText>
                    </div>
                  </Col>
                  <Col
                    md={4}
                    style={{
                      paddingRight: 0,
                    }}
                    className="footer-section-end"
                  >
                    <div className="d-flex flex-column">
                      <Header
                        style={{
                          textAlign: "right",
                        }}
                      >
                        اللغات
                      </Header>

                      {courses.map((a) => (
                        <SubText
                          style={{
                            textAlign: "right",
                          }}
                          target="_blank"
                          href={`https://staging.ulearn.study/kw/pricing?course=${a.en}`}
                        >
                          {a.ar}
                        </SubText>
                      ))}
                    </div>
                  </Col>
                  <Col
                    md={4}
                    style={{
                      paddingRight: 0,
                    }}
                    className="footer-section-end"
                  >
                    <div className="d-flex flex-column">
                      <Header
                        style={{
                          textAlign: "right",
                        }}
                      >
                        طريقتنا بالتعلم
                      </Header>
                      <SubText
                        style={{
                          textAlign: "right",
                        }}
                        href={"#our-ways"}
                      >
                        طريقة الاستشارة
                      </SubText>
                      <SubText
                        style={{
                          textAlign: "right",
                        }}
                        href={props.query ? `/career${props.query}` : "/career"}
                      >
                        اختبار تحديد التخصص
                      </SubText>
                    </div>
                  </Col>
                </div>
              </Col>
            </div>
          </>
          <Col md={12}>
            {" "}
            <div className="d-flex justify-content-end w-100">
              <SubText
                style={{ padding: 0 }}
                href="https://firebasestorage.googleapis.com/v0/b/digital-vim-270607.appspot.com/o/Guide-for-Choosing-the-right-UK-Education-Agent-Final.pdf?alt=media&token=4ce285b7-e123-419b-a2d0-d3c764a66cb9"
                className="footer-subtext"
                target="_blank"
              >
                دليل اختيار المرشد الاكاديمي
              </SubText>
            </div>
          </Col>
          <div
            className={"d-flex flex-row res-column-footer"}
            style={{ paddingTop: 44 }}
          >
            <Col
              md={12}
              className="pc-view"
              style={{ paddingBottom: 40, paddingRight: 0 }}
            >
              <div className={"d-flex justify-content-end"}>
                <a
                  href="https://www.linkedin.com/company/ulearn-for-academic-services"
                  target="_blank"
                  style={{
                    paddingRight: 25,
                    textDecoration: "none",
                  }}
                  rel="noreferrer"
                >
                  <LinkedIn color="#1E2432" width={24} height={24} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCj6FYNv9hLPp6CJKyl0LSuw/featured?view_as=subscriber"
                  style={{
                    paddingRight: 25,
                    textDecoration: "none",
                  }}
                >
                  <Youtube color="#1E2432" width={24} height={24} />
                </a>
                <a
                  href="https://www.instagram.com/ulearnabroad/"
                  style={{
                    paddingRight: 25,
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram color="#1E2432" width={24} height={24} />
                </a>

                <a
                  href="https://twitter.com/ulearnstudy"
                  style={{
                    paddingRight: 25,
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter color="#1E2432" width={24} height={24} />
                </a>
                <a
                  href="https://www.facebook.com/Ulearnabroad"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook color="#1E2432" width={24} height={24} />
                </a>
              </div>
            </Col>
            {/* <Col md={8}> */}
            {/* <RowCol>
                <Col md={12}>
                  <div
                    className={`d-flex flex-row ${"d-flex justify-content-end padding-bottom-10"}`}
                  >
                    <div>
                      <PaymentIcon style={{ marginRight: 20 }} />

                      <img
                        src="https://img.favpng.com/14/18/21/sadad-payment-system-saudi-arabia-bank-service-png-favpng-UPtbxUJkUD9t7mJgm5hHthaEB.jpg"
                        alt="icon"
                        width="27px"
                        height="18px"
                        style={{ marginRight: 20 }}
                      />
                      <PaymentIcon2 style={{ marginRight: 20 }} />
                      <PaymentIcon3 />
                    </div>
                    <SubText style={{ paddingLeft: 24 }}>
                      <SubText
                        style={{
                          fontWeight: "normal",
                          cursor: "auto",
                        }}
                      >
                        طرق الدفع
                      </SubText>
                    </SubText>
                  </div>
                </Col>
              </RowCol> */}
            {/* </Col> */}
          </div>
        </Container>
        <ContainerArcks />
      </div>
    </div>
  );
}

// #region Global Imports
// #endregion Global Imports

export const Header = styled.span`
  color: #1e2432;
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  padding-bottom: 20px;
  letter-spacing: -0.500211px;
`;

export const SubText = styled.a`
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 13.9806px;
  line-height: 28px;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  color: #1e2432;
  &:hover {
    text-decoration: none;
    color: #1e2432;
  }
`;
export const StartBtn = styled.button`
  padding: 8px 16px;
  width: 132px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 121.83px;
  background-color: #fff;
`;
export const ContainerArcks = styled.div`
  background-size: cover;
  height: 88.49px;
  background-image: url("https://cdn.ulearn.study/courses/BG-min.png");
`;

export const RowCol = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;
