import React from "react";

function Twitter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "32"}
      height={props.height ? props.height : "32"}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={props.color ? props.color : "#fff"}
        d="M30 8.612a9.275 9.275 0 01-3.08.844 5.435 5.435 0 002.352-3.034A9.941 9.941 0 0125.87 7.77a5.103 5.103 0 00-1.72-1.31A4.92 4.92 0 0022.064 6c-.703 0-1.398.143-2.047.423-.649.28-1.239.689-1.735 1.206a5.582 5.582 0 00-1.159 1.805 5.757 5.757 0 00-.406 2.13c.014.426.068.85.163 1.264a14.81 14.81 0 01-6.1-1.697 15.404 15.404 0 01-4.918-4.12 5.461 5.461 0 00-.731 2.775c.006.913.223 1.811.633 2.619a5.62 5.62 0 001.723 2.02 4.82 4.82 0 01-2.431-.677v.085a5.658 5.658 0 001.218 3.496 5.269 5.269 0 003.073 1.899 7.673 7.673 0 01-1.374.17 3.737 3.737 0 01-.97-.085 5.628 5.628 0 001.885 2.747 5.278 5.278 0 003.056 1.13 10.205 10.205 0 01-6.642 2.363A6.653 6.653 0 014 25.468a14.699 14.699 0 007.644 2.52 14.63 14.63 0 007.806-1.905 15.395 15.395 0 005.77-5.795 16.21 16.21 0 002.107-8.05v-.677A15.094 15.094 0 0030 8.612z"
      ></path>
    </svg>
  );
}

export default Twitter;
