import { LANG, SHOW, SHOW_MAJOR } from "./types";

export const updateLanguage = (lang) => {
  return async (dispatch) => {
    try {
      const res = lang;

      dispatch({
        type: LANG,
        payload: res,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const showAnimation = (show) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SHOW,
        payload: show,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const showMajorAnimation = (showMajor) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SHOW_MAJOR,
        payload: showMajor,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
