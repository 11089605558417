import React, { useContext, useState, useEffect } from "react";
import { Footer, Header } from "../Component";
import CheckCircle from "../Assets/Images/CheckCircle";
import { Button, Container, Navbar } from "react-bootstrap";
import minusIcon from "../Assets/Images/minusIcon.svg";
import plus from "../Assets/Images/plus.svg";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import { Accordion, Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Logo from "../Assets/Images/Logo";
import * as questionData from "../Constant/questions";
import WhatsAppIcon from "../Assets/Images/WhatsAppIcon";
import { useParams } from "react-router-dom";
import { PopupButton } from "react-calendly";
import { AnimationSection } from "./styled/result";
import { SuccessAnimation } from "../Component/SuccessAnimation/SuccessAnimation";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { dataAction } from "../state";

export default function Result(props) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  //   const router = useRouter();

  useEffect(() => {
    document.getElementById("html").style.overflow = "scroll";

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  function CustomToggle({ title, eventKey, customPadding }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => {
      // console.log.*$
      // console.log.*$
    });
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: 0,
        }}
      >
        <button
          type="button"
          style={{
            width: "100%",
            backgroundColor: "#fff",
            padding: 0,
            outline: "none",
            border: "none",
          }}
          onClick={decoratedOnClick}
        >
          <div
            className="d-flex flex-row justify-content-between w-100"
            style={{ padding: customPadding ? "0 0 24px 0" : "36px 0 24px 0" }}
          >
            {<img src={isCurrentEventKey ? minusIcon : plus} alt="icon" />}
            <p
              className="faq-selection"
              style={{
                ...style.title,
                direction: "rtl",
                color: isCurrentEventKey ? "#35A7FF" : "#222528",
              }}
            >
              {title}
            </p>
          </div>
        </button>
      </div>
    );
  }
  const location = useLocation();

  let majors = location.state.data;
  let destination = location.state.destination;
  const questionText = "ما هو تخصص";
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let utm_source = params.get("utm_source");
  let utm_content = params.get("utm_content");
  let utm_medium = params.get("utm_medium");
  let utm_campaign = params.get("utm_campaign");
  let utm_term = params.get("utm_term");

  const dispatch = useDispatch();
  const { showMajorAnimation } = bindActionCreators(dataAction, dispatch);
  const data = useSelector((state) => state.data);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      {data.showMajor ? (
        <AnimationSection>
          <SuccessAnimation
            onComplete={async () => {
              showMajorAnimation(false);
            }}
          />
        </AnimationSection>
      ) : null}
      <Navbar
        style={{
          height: "79.89px",
          backgroundColor: "#fff",
          boxShadow:
            scrollPosition === 0 ? "none" : "inset 0px -1px 0px #E8E9F5",
          padding: 20,
        }}
        sticky="top"
      >
        <Container>
          <div className=" d-flex justify-content-between w-100">
            <a href="/home">
              {" "}
              <Logo />
            </a>

            <a
              target={"_blank"}
              href="https://wa.me/96566687847"
              className="d-flex flex-row align-items-center justify-content-end"
            >
              <div style={{ marginRight: 8 }}>
                <WhatsAppIcon />
              </div>
              <span className="contact-text">للاستفسارات</span>
            </a>
          </div>
        </Container>
      </Navbar>
      <Container>
        <div className="d-flex flex-column result-header-con">
          <span className="result-header">نتيجة اختبار تحديد التخصص</span>
          <span
            className="result-subtext"
            style={{ paddingTop: 2, direction: "rtl" }}
          >
            نشكركم على اختياركم اختبار تحديد التخصص مع يوليرن. لقد أنهيت
            الاختبار.
          </span>
        </div>
        <div className="d-flex flex-column w-100 align-items-center">
          <div className="result-card-badge">ulearn</div>
          <div className="result-card ">
            <div className="d-flex flex-column">
              <span className="result-card-header">
                التخصصات التي قد تناسبك
              </span>
              <span className="result-card-subtext">
                بناء على اختياراتك في الأسئلة السابقة
              </span>
            </div>
            <div
              style={{
                borderBottom: "1px solid #E8E9F5",
                margin: "22px 0 38px 0",
              }}
            />

            <div className="d-flex flex-column">
              {majors.map((a) => (
                <div
                  className="d-flex flex-row justify-content-end"
                  style={{ paddingBottom: 15 }}
                >
                  <span className="result-card-listtext">
                    {questionData.arabicMajors[a]}
                  </span>
                  <CheckCircle />
                </div>
              ))}
            </div>
            {destination === "بريطانيا" || destination === "تركيا" ? (
              <div
                style={{
                  borderBottom: "1px solid #E8E9F5",
                  margin: "38px 0",
                }}
              />
            ) : null}

            {destination === "بريطانيا" || destination === "تركيا" ? (
              <div>
                <PopupButton
                  className="banner-btn"
                  url="https://calendly.com/ulearn/website?hide_gdpr_banner=1"
                  utm={{
                    utmCampaign: utm_campaign,
                    utmContent: utm_content,
                    utmMedium: utm_medium,
                    utmSource: utm_source,
                    utmTerm: utm_term,
                  }}
                  rootElement={document.getElementById("root")}
                  text="احجز موعد استشارة هاتفية مجانية"
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="container" style={{ paddingBottom: 140 }}>
          <div style={{ margin: "118px 0 89px 0" }}>
            <span className="result-findout-text">
              تعرف على التخصصات الأنسب لك
            </span>
          </div>
          <Accordion>
            {majors.map((a, key) => (
              <div style={{ borderBottom: "1px solid #e3e9ef" }}>
                <CustomToggle
                  eventKey={key}
                  title={`${questionText} ${questionData.arabicMajors[a]}؟`}
                  customPadding={key == 0 ? true : false}
                />
                <Accordion.Collapse eventKey={key}>
                  <div>
                    <div style={{ marginBottom: 24, textAlign: "start" }}>
                      <p style={style.subtext}>{questionData.majorDesc[a]}</p>
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>
            ))}
          </Accordion>
        </div>
      </Container>
      <a
        className="whatsapp-fixed-icon-con"
        target="_blank"
        href={"https://wa.me/96566687847"}
      >
        <img
          className="whatsapp-fixed-icon-con"
          src="https://storage.googleapis.com/destination_ulearn/courses/whatsappIcon.png"
          alt="icon"
        />
      </a>
    </div>
  );
}

const style = {
  heaader: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 30,
    color: "#333333",
    marginBottom: 60,
    //   marginTop: 115,
  },
  subtext: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    transition: "all 5s cubic-bezier(0.63, 1.17, 1, 1) 0s",
    color: "#222528",
    cursor: "default",
    direction: "rtl",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 30,
    textAlign: "right",
    marginLeft: 24,
    lineHeight: 1.5,
    color: "#222528",
  },
  text: {
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: 2,
    fontSize: 21,
    color: "#333333",
  },
};
