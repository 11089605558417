import * as React from "react";
import { Field } from "formik";
import "./style.scss";

import { Label } from "./styled";

export const FormInputComponent = (props) => {
  return (
    <>
      <Label>{props.label}</Label>
      <Field
        id={props.id}
        style={props.style}
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        className={`form-input-custom ${props.className}`}
      />
    </>
  );
};
