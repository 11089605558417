import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CareerTest } from "../Views/CareerTest";
import Home from "../Views/Home";
import MajorTest from "../Views/MajorTest";
import Result from "../Views/Result";
import { Apply } from "../Views/Apply";
import ResultUapply from "../Views/ResultUapply";
import GoogleForm from "../Views/GoogleForm";
import { Success } from "../Views/Success";
import { Failed } from "../Views/Failed";

export default function Router() {
  return (
    <Routes>
      <Route path="/career" element={<CareerTest />} />
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/major-test" element={<MajorTest />} />
      <Route path="/apply" element={<Apply />} />
      <Route path="/apply/:language" element={<Apply />} />
      <Route path="/test-results" element={<Result />} />
      <Route path="/google-form" element={<GoogleForm />} />
      <Route path="/:language/success" element={<ResultUapply />} />
      <Route path="/kw/booking/success" element={<Success />} />
      <Route path="/booking/failed" element={<Failed />} />
    </Routes>
  );
}
