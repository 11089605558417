// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Dots = styled.div(
  ({ color, arabic }) => `
    width: 28px;
    height: 28px;
    background: #F7F8F9;
    border: 2px solid ${color};
    border-radius: 14px;
    transform: rotate(90deg);
  @media (max-width: 992px) {
    width: 14px;
    height: 14px;
    border-radius: 10px;
    }
        `
);

export const Text = styled.div(
  ({ arabic }) => `
  font-family: ${arabic ? "Cairo" : "Dm Sans"};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #676767;
  direction: ${arabic ? "rtl" : "ltr"};
  text-align: ${arabic ? "right" : "left"};

`
);

export const InputComponent = styled.input(
  ({ arabic }) => `
  text-align:${arabic ? "right" : "left"};
  direction: ${arabic ? "rtl" : "ltr"};
  font-family: ${arabic ? "Cairo" : "Dm Sans"};
  ::placeholder {
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    direction:rtl;
    color: #99a5b4;
  }
  
        `
);

export const QuestionText = styled.div(
  ({ arabic }) => `
  font-family:${arabic ? "Cairo" : "Dm Sans"};
  font-style: normal;
  font-weight: 700;
  animation: 3s infinite alternate slidein;
  font-size: 18px;
  line-height: 36px;
  direction: ${arabic ? "rtl" : "ltr"};
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
}
`
);

export const OptionBtn = styled.div(
  ({ justifyContent, marginTop, arabic, width, selected }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${justifyContent};
    text-align:center;
    padding: 12px;
    height: 56px;
    direction:rtl;
    width: ${width ? width : "392px"};
    margin-bottom: 12px;
    margin-top:${marginTop};
    background: ${selected ? "#000" : "#fff"};
    cursor:pointer;
    border: 1.5px solid #191d23;
    border-radius: 8px;
    font-family:${arabic ? "Cairo" : "Dm Sans"} ;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 44px;
    color:${selected ? "#fff" : "#191c1f"} ;
    &:hover {
      background: #e7eaee;
    }
    @media (max-width: 992px) {
      width: ${width ? width : "100%"} ;
      &:hover {
        background: transparent !important;
      }
    }
`
);

export const SubjectCon = styled.div`
  background: #e7eaee;
  color: #191d23;
  border-radius: 40px;
  padding: 4px;
  margin-top: 10px;
  width: max-content;
  texttransform: capitalize;
  padding-left: 15px;
  margin-right: 10px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const Label = styled.span(
  ({ arabic }) => `
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 4px;
  text-align:${arabic ? "right" : "left"};
  direction: ${arabic ? "rtl" : "ltr"};
  
        `
);

export const FormContainer = styled.div`
  width: 384px;
  display: flex;
  justify-content: center;
  @media (max-width: 992px) {
    width: 100%;
  }
`;
