import * as React from "react";

export const CustomCheckbox = (props) => {
  return (
    <ul
      style={{ direction: props.arabic ? "rtl" : "ltr" }}
      className="unstyled centered"
    >
      <li onClick={() => props.onChange()}>
        <input
          className="styled-checkbox"
          id="styled-checkbox-1"
          type="checkbox"
          name={props.label}
          checked={props.checked}
          style={{}}
          readOnly
        />
        <label className="d-flex align-items-center">{props.label}</label>
      </li>
    </ul>
  );
};
