import React from "react";

function TimeupIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="69"
      fill="none"
      viewBox="0 0 105 69"
    >
      <g clipPath="url(#clip0_31277_66438)">
        <mask
          id="mask0_31277_66438"
          style={{ maskType: "alpha" }}
          width="106"
          height="30"
          x="0"
          y="30"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M.779 30.246h104.374v29.499H.779V30.246z"
            clipRule="evenodd"
          ></path>
        </mask>
        <g mask="url(#mask0_31277_66438)">
          <path
            fill="#E8F0FC"
            fillRule="evenodd"
            d="M56.78 52.292c1.268 2.441 2.618 4.919 4.04 7.302a145.182 145.182 0 005.252-15.506l.326-.043.327-.05a144.102 144.102 0 009.352 13.44c.695-2.73 1.319-5.412 1.85-8.135.517-2.662.963-5.287 1.327-7.924.105-.03.211-.056.317-.086.106-.03.212-.062.318-.091 1.673 2.049 3.43 4.113 5.242 6.084a144.461 144.461 0 005.814 5.992c.326-2.8.577-5.558.741-8.31.151-2.708.225-5.385.226-8.03.1-.043.201-.084.3-.128.102-.044.201-.09.302-.134a136.25 136.25 0 006.03 5.3 141.823 141.823 0 006.609 5.109 142.3 142.3 0 00-.45-8.338 133.946 133.946 0 00-.96-7.967c.094-.058.189-.114.281-.172a.194.194 0 10-.205-.33 90.199 90.199 0 01-11.761 6.176 97.216 97.216 0 01-12.592 4.465 102.007 102.007 0 01-13.123 2.744c-4.422.625-8.923.965-13.379 1.01a99.995 99.995 0 01-13.365-.758 92.994 92.994 0 01-13.061-2.582 83.23 83.23 0 01-12.41-4.476c-4.04-1.839-7.856-3.998-11.343-6.419a.194.194 0 10-.222.32c.089.061.18.12.267.18-.531 2.572-.99 5.224-1.33 7.897a111.452 111.452 0 00-.721 8.332 112.87 112.87 0 006.773-4.906 113.58 113.58 0 006.122-5.187c.098.046.194.093.292.137.099.045.199.087.297.132-.13 5.378.141 10.843.793 16.344a121.681 121.681 0 0011.065-12.069l.314.09.317.084c.367 2.628.815 5.293 1.363 7.91.559 2.698 1.2 5.441 1.942 8.12 1.71-2.236 3.33-4.454 4.88-6.773 1.51-2.252 2.93-4.475 4.288-6.792l.325.044c.108.013.219.024.328.038a129.484 129.484 0 002.481 7.637c.932 2.595 1.946 5.22 3.044 7.772a141.755 141.755 0 007.236-14.685c.11 0 .22 0 .329-.002l.331-.006a135.878 135.878 0 003.482 7.24z"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          fill="#E8F0FC"
          fillRule="evenodd"
          d="M79.221 73.995a82.938 82.938 0 01-12.143-4.445 72.753 72.753 0 01-11.106-6.316l-.222.32c.087.06.175.118.262.177a106.23 106.23 0 00-1.389 7.862c-.357 2.709-.63 5.49-.793 8.295 2.332-1.589 4.567-3.22 6.72-4.923a118.343 118.343 0 006.08-5.197c.096.044.19.09.285.135l.292.13a114.61 114.61 0 00.008 7.993c.109 2.731.296 5.533.594 8.305a127.969 127.969 0 005.809-5.96 130.853 130.853 0 005.191-6.091c.103.029.204.06.307.09.103.028.207.055.31.084a127.16 127.16 0 001.252 7.901 138.225 138.225 0 001.818 8.12 143.235 143.235 0 004.86-6.748 146.226 146.226 0 004.278-6.769l.318.045c.107.015.214.027.322.04.708 2.54 1.488 5.116 2.352 7.652.888 2.59 1.855 5.202 2.904 7.794a160.624 160.624 0 003.899-7.341 156.364 156.364 0 003.343-7.276l.325.003v-.389a99.95 99.95 0 01-13.094-.868 92.6 92.6 0 01-12.782-2.623zM51.033 63.571l.27-.165-.206-.33a90.021 90.021 0 01-11.305 5.98 96.792 96.792 0 01-12.089 4.396 101.706 101.706 0 01-12.6 2.798 103.395 103.395 0 01-12.867 1.193l.01.39c.107-.004.214-.01.32-.013a157.558 157.558 0 003.496 7.177 164.086 164.086 0 004.033 7.241 165.033 165.033 0 002.634-7.858c.778-2.571 1.483-5.143 2.118-7.698.105-.016.21-.03.316-.047l.316-.052a156.61 156.61 0 004.366 6.682c1.563 2.242 3.2 4.47 4.913 6.678a162.148 162.148 0 001.625-8.129c.447-2.647.818-5.288 1.122-7.902.102-.03.205-.057.307-.087l.306-.091a151.655 151.655 0 005.182 6.07 157.402 157.402 0 005.736 5.987c.262-2.785.448-5.545.557-8.273.097-2.684.114-5.35.063-7.98.098-.042.196-.083.293-.126.097-.042.193-.088.29-.13a143.37 143.37 0 005.943 5.323 146.743 146.743 0 006.506 5.145 144.54 144.54 0 00-.586-8.275c-.28-2.671-.641-5.313-1.069-7.904z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#FD9E28"
          fillRule="evenodd"
          d="M17.712 27.7a1.017 1.017 0 10-2.034 0 1.017 1.017 0 002.034 0z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#3C76D6"
          fillRule="evenodd"
          d="M39.007 25.925a1.017 1.017 0 10-2.034 0 1.017 1.017 0 002.034 0z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#BAD0F7"
          fillRule="evenodd"
          d="M31.497 27.811a7.168 7.168 0 01-3.249-3.667l-2.143 2.144a7.198 7.198 0 003.25 3.666l2.142-2.143z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#FD3E60"
          fillRule="evenodd"
          d="M74.637 21.683a4.79 4.79 0 013.264.197v-2.022a4.79 4.79 0 00-3.264-.197v2.022z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#2B9086"
          fillRule="evenodd"
          d="M12.415 44.828a4.785 4.785 0 013.264.198v-2.024a4.79 4.79 0 00-3.264-.197v2.023z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#FD3E60"
          fillRule="evenodd"
          d="M86.367 64.762a4.782 4.782 0 01.198-3.264H84.54a4.808 4.808 0 00-.197 3.264h2.023z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#3C76D6"
          fillRule="evenodd"
          d="M94.539 58.676a7.215 7.215 0 01-.794 1.899 7.174 7.174 0 01-1.978 2.14l.957 1.15a7.184 7.184 0 002.434-1.602c.5-.5.91-1.057 1.232-1.648l-1.851-1.94z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#BAD0F7"
          fillRule="evenodd"
          d="M80.67 27.701a6.264 6.264 0 01-.688 1.65 6.232 6.232 0 01-1.718 1.858l.83.997a6.26 6.26 0 003.183-2.821l-1.606-1.684z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#3C76D6"
          fillRule="evenodd"
          d="M18.44 66.311a5.04 5.04 0 012.844 1.951l.81-.673a5.055 5.055 0 00-2.289-2.581L18.44 66.31z"
          clipRule="evenodd"
        ></path>
        <path
          stroke="#E3B097"
          strokeLinecap="round"
          strokeWidth="0.389"
          d="M89.355 43.217a.987.987 0 10-.186 1.962M84.643 46.02c-1.11-.264-1.786-1.263-2.16-2.577M88.245 37.1c-.608.198-1.033.564-.945 1.16.1.664.651.922 1.154.837.341-.059.87-.139 1.405-.218"
        ></path>
        <path
          stroke="#E3B097"
          strokeLinecap="round"
          strokeWidth="0.389"
          d="M88.382 39.102c-.807.05-1.351.772-1.063 1.528.196.515.687.651 1.235.583.273-.035.766-.055 1.305-.071"
        ></path>
        <path
          stroke="#E3B097"
          strokeLinecap="round"
          strokeWidth="0.389"
          d="M88.599 41.209c-.663.043-1.117.61-.929 1.247.13.437.538.73.993.713l1.196.067"
        ></path>
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.389"
          d="M65.954 47.664l.706-7.574"
        ></path>
        <path
          stroke="#E3B097"
          strokeLinecap="round"
          strokeWidth="0.389"
          d="M35.25 65.508c-.578.693-1.43 1.765-1.672 2.814M41.592 70.174l-2.379-2.01"
        ></path>
        <path
          stroke="#FD3E60"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M52.913 52.498c6.904 0 12.5-5.596 12.5-12.5s-5.596-12.5-12.5-12.5c-6.903 0-12.5 5.596-12.5 12.5s5.597 12.5 12.5 12.5z"
        ></path>
        <path
          stroke="#FD3E60"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.438"
          d="M52.913 32.5V40l5 2.5"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_31277_66438">
          <path
            fill="#fff"
            d="M0 0H104.085V68.544H0z"
            transform="translate(.915)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TimeupIcon;
