import React, { useEffect } from "react";
import { Card, Col, Container } from "react-bootstrap";
import { HeaderText, SectionMargin } from "./styled/result";

export const Success = () => {
  useEffect(() => {
    async function fetchData() {
      // const booking = await getBooking(ctx?.query.id);
      // const course = await getCoursebyId(booking.course);
      // const level = await getLevelbyId(booking.level);
      // const ageGroup = await getAgeGroupById(booking.ageGroup);
      // const currency = await getCurrencybyId(booking.currencyId);
      // const duration = await getDurationbyId(booking.duration);
    }
    fetchData();
  }, []);

  return (
    <div>
      <SectionMargin />
      <Container>
        <div className="d-flex justify-content-center">
          <Col md={6} xs={12}>
            <Card
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                // boxShadow:
                //   "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
              }}
            >
              <HeaderText>Payment Successfull</HeaderText>
              <br />
              <div className="d-flex justify-content-center">
                <img
                  height={"100px"}
                  width={"100px"}
                  src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.nicepng.com%2Fpng%2Ffull%2F362-3624869_icon-success-circle-green-tick-png.png&f=1&nofb=1&ipt=29c1afe489b9ff808b4e17ce3c6da63bf5c59aa7f4b864af1b23a840653ca46d&ipo=images"
                  alt="img"
                />
              </div>
              <br />
              <br />
              {/* <Border />
              <br />
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between">
                  <Subtext>Payment Mode</Subtext>
                  <Subtext style={{ fontWeight: "bold" }}>
                    {bookinng.modeOfPayment || "N/A"}
                  </Subtext>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <Subtext>Full Name</Subtext>
                  <Subtext style={{ fontWeight: "bold" }}>
                    {bookinng.fullname}
                  </Subtext>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <Subtext>Mobile Number</Subtext>
                  <Subtext
                    style={{ fontWeight: "bold" }}
                  >{`${bookinng.countryCode} ${bookinng.phoneNo}`}</Subtext>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <Subtext>Email</Subtext>
                  <Subtext style={{ fontWeight: "bold" }}>
                    {bookinng.email}
                  </Subtext>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <Subtext>Amount Paid</Subtext>
                  <Subtext style={{ fontWeight: "bold", fontSize: 20 }}>
                    {bookinng.price}.00 KWD
                  </Subtext>
                </div>
              </div>
              <br />
              <Border /> */}

              <br />
            </Card>
          </Col>
        </div>
      </Container>
    </div>
  );
};
