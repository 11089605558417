import React from "react";

function ArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      style={props.style}
    >
      <path
        stroke="#1E2432"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.334 4.667L8 11.333l6.667-6.666"
      ></path>
    </svg>
  );
}

export default ArrowDown;
