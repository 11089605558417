import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Dropdown } from "react-bootstrap";
import ArrowDown from "../Assets/Images/ArrowDown";
import { useNavigate } from "react-router-dom";
import { CircleCross } from "../Assets/Images/CircleCross";

export default function CountryModal(props) {
  const [countrySelected, setcountrySelected] = useState("");
  let history = useNavigate();

  return (
    <Modal
      {...props}
      size="lg"
      className="careerModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="w-100" style={{ borderBottom: "none" }}>
        <div
          className="d-flex justify-content-start w-100"
          style={{ cursor: "pointer" }}
          onClick={() => props.onHide()}
        >
          <CircleCross />
        </div>
      </Modal.Header>
      <Modal.Body style={{ overflow: "hidden" }}>
        <div className="country-modal-header ">
          <h4 style={{ textAlign: "center" }}>في أي بلد ترغب بالدراسة؟</h4>
        </div>
        <div className="d-flex justify-content-center w-100">
          <Dropdown style={{ width: 443 }}>
            <Dropdown.Toggle
              style={{ width: "100%" }}
              variant=""
              id="dropdown-basic"
              className="custom-dd"
            >
              <div className="d-flex justify-content-between w-100 align-items-center">
                <ArrowDown />
                <p style={{ margin: 0 }}>
                  {countrySelected
                    ? countrySelected
                    : "اختر الدولة التي ترغب الدراسة فيها"}
                </p>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="custom-dropdown-menu">
              {props.countries.countryList.map((a) => (
                <Dropdown.Item
                  onClick={() => setcountrySelected(a)}
                  style={{ padding: "10px 20px 10px 12px" }}
                >
                  <div className="d-flex justify-content-end w-100 align-items-center">
                    <p>{a}</p>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Modal.Body>
      <Modal.Footer
        className="d-flex justify-content-start"
        style={{ border: "none" }}
      >
        <Button
          style={{ width: 158, marginTop: 0 }}
          className="custom-black-btn"
          disabled={!countrySelected}
          onClick={() => {
            history(props.query ? `/major-test${props.query}` : "/major-test", {
              state: { country: countrySelected },
            });
          }}
        >
          ابدأ الاختبار
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
