import * as React from "react"

function Youtube(props) {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M22.791 6.602a2.818 2.818 0 00-1.98-1.986c-1.761-.483-8.804-.491-8.804-.491s-7.042-.008-8.803.454a2.878 2.878 0 00-1.986 1.999C.754 8.338.75 11.99.75 11.99s-.005 3.67.456 5.412a2.817 2.817 0 001.982 1.984c1.778.483 8.802.491 8.802.491s7.043.008 8.804-.453a2.828 2.828 0 001.986-1.982c.465-1.76.469-5.41.469-5.41s.022-3.67-.458-5.43zm-13.037 8.77l.006-6.745 5.853 3.378-5.859 3.367z"
          fill={props.color}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Youtube
