import * as React from "react";

function CalenderIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#444A58"
        fillRule="evenodd"
        d="M6 5.947a1.512 1.512 0 001.512-1.514V1.704a1.512 1.512 0 00-3.024 0v2.73A1.512 1.512 0 006 5.946zM18 5.947a1.513 1.513 0 001.512-1.514V1.704a1.512 1.512 0 00-3.024 0v2.73A1.514 1.514 0 0018 5.946z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#444A58"
        fillRule="evenodd"
        d="M21.717 2.215v2.494a3.864 3.864 0 01-3.333 3.91 3.716 3.716 0 01-4.099-3.697V2.818a.617.617 0 00-.617-.615h-3.336a.615.615 0 00-.615.615v1.886A3.864 3.864 0 016.384 8.62a3.716 3.716 0 01-4.099-3.698V2.215A2.4 2.4 0 000 4.603v16.8a2.4 2.4 0 002.4 2.4h19.2a2.4 2.4 0 002.4-2.4v-16.8a2.4 2.4 0 00-2.283-2.388zm-2.003 16.257a1.466 1.466 0 01-1.46 1.46H5.397a1.465 1.465 0 01-1.461-1.46V12.78a1.466 1.466 0 011.466-1.466h12.857a1.466 1.466 0 011.46 1.461l-.005 5.697z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CalenderIcon;
