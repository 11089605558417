import React from "react";

function ChatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
    >
      <path
        fill="#fff"
        stroke="#48C857"
        strokeWidth="2"
        d="M61 44c0 7.732-6.268 14-14 14s-14-6.268-14-14 6.268-14 14-14 14 6.268 14 14z"
      ></path>
      <path
        stroke="#48C857"
        strokeLinecap="square"
        strokeWidth="2"
        d="M46.956 46.348c0-2.087 1.645-2.436 2.211-3.001A3.13 3.13 0 1044 40.098"
      ></path>
      <circle cx="47" cy="50" r="1" fill="#48C857"></circle>
      <path
        fill="#48C857"
        d="M47 29a14.924 14.924 0 018.3 2.507c.464-1.592.7-3.242.7-4.9C56 14.671 43.912 5 29 5S2 14.671 2 26.6c0 6.068 3.133 11.55 8.17 15.474L4 54l17.615-6.625c2.423.55 4.9.826 7.385.825 1.184 0 2.367-.062 3.545-.185l.013-.009A14.961 14.961 0 0147 29z"
      ></path>
      <path
        fill="#000064"
        d="M36.383 33.373l-2.983 2.98-.021.02-.565.565.041.012A14.926 14.926 0 0029 46.968c0 .4.029.8.061 1.2a34.233 34.233 0 003.485-.183l.013-.009a14.917 14.917 0 013.829-14.606l-.005.003z"
        opacity="0.15"
      ></path>
    </svg>
  );
}

export default ChatIcon;
