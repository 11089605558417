/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import { debounce } from "debounce";
import { Col, Modal, ProgressBar, Row } from "react-bootstrap";
// import { MultiStepProgressBar } from "../MultiStepProgressBar/MultiStepProgressBar";
import {
  FormContainer,
  InputComponent,
  Label,
  OptionBtn,
  QuestionText,
  Text,
} from "./styled";

import {
  createStudent,
  searchCountries,
  searchUniversities,
} from "../../Services/Function";
import { Form, Formik, FormikHelpers } from "formik";

import { CustomCheckbox } from "../CustomCheckbox";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { ButtonComponent } from "../ButtonComponent";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "../../Views/styled/result";
import { useTranslation } from "react-i18next";
// import { PhonenoComponent } from "../PhonenoComponent";
import { UniversityInputComponent } from "../UniversityInputComponet";
import { CountryInputComponent } from "../CountryInputComponent";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { dataAction } from "../../state";
import { useState } from "react";
import { BackIcon } from "../../Assets/Images/BackIcon";
import { CircleCross } from "../../Assets/Images/CircleCross";
import { FormInputComponent } from "../FormInputComponent";

export const ModalComponent = (props) => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = React.useState("1");
  const [countries, setcountries] = React.useState([]);
  const [selectedCountry, setselectedCountry] = React.useState({});
  const [selectedResidence, setselectedResidence] = React.useState({});
  const [accomplished, setaccomplished] = React.useState(["pageone"]);
  const [sponsored, setsponsored] = useState(null);
  const [sponsoredBy, setsponsoredBy] = useState("");
  // const [subjects, setsubjects] = React.useState([]);
  const [universities, setuniversities] = React.useState([]);
  const [selectedUniversity, setselectedUniversity] = React.useState([]);
  const [selectedSubject, setselectedSubject] = React.useState([]);
  const [searchText, setsearchText] = React.useState("");
  const [destination, setdestination] = React.useState({});
  const [degree, setdegree] = React.useState({});
  const [loading, setloading] = React.useState(false);
  const [takenIltes, settakenIltes] = React.useState(null);
  const [iltesScore, setiltesScore] = React.useState("");
  const [cgpa, setcgpa] = React.useState("");
  const [previousSchool, setpreviousSchool] = React.useState("");
  const [moreThan50000, setmoreThan50000] = React.useState(false);
  const [engLevel, setengLevel] = React.useState("");
  const [countryVisited, setcountryVisited] = React.useState([
    { country: "europe", key: 3, status: false },
    { country: "australia", key: 0, status: false },
    { country: "america", key: 1, status: false },
    { country: "uk", key: 2, status: false },
    { country: "notVisited", key: 4, status: false },
  ]);
  const [phoneNo, setphoneNo] = React.useState("");
  const [errorMessage, seterrorMessage] = React.useState("");
  const [majors, setmajors] = React.useState("");

  const arabic = i18n.language === "ar";
  const nextPage = (page) => {
    setPage(page);
  };
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { showAnimation } = bindActionCreators(dataAction, dispatch);

  const checkQualified =
    selectedCountry.english_name === "United States of America" ||
    selectedCountry.english_name === "Australia" ||
    selectedCountry.english_name === "Oman" ||
    selectedCountry.english_name === "France" ||
    selectedCountry.english_name === "Spain" ||
    selectedCountry.english_name === "United Kingdom" ||
    selectedCountry.english_name === "Qatar" ||
    selectedCountry.english_name === "Kuwait" ||
    selectedCountry.english_name === "Saudi Arabia" ||
    selectedCountry.english_name === "United Arab Emirates" ||
    selectedCountry.english_name === "Bahrain" ||
    selectedCountry.english_name === "Germany";

  // const handleRemoveItem = (e ) => {
  //   const name = e;
  //   setselectedSubject(selectedSubject.filter((item ) => item !== name));
  // };
  const onChangeDestination = (data) => {
    accomplished.push("pageone");
    nextPage("2");
    setdestination(data);
  };
  const onChangeDegree = (data) => {
    accomplished.push("pagethree");
    nextPage("4");
    setdegree(data);
  };
  const addCountryVisited = (data) => {
    countryVisited.push(data);
  };

  const searchCountry = debounce(async (e) => {
    if (!e.target.value) {
      setcountries([]);
    } else {
      const res = await searchCountries({
        text: e.target.value,
        sortBy: "createdAt:desc",
        limit: 7,
      });
      setcountries(res?.results);
    }
  }, 500);
  const formRef = React.useRef();

  const searchUniversity = debounce(async (e) => {
    if (!e.target.value) {
      setuniversities([]);
    } else {
      const res = await searchUniversities({
        text: e.target.value,
        sortBy: "createdAt:desc",
        limit: 5,
      });
      setuniversities(res?.results);
    }
  }, 500);

  let checkCountryVisited = countryVisited
    .filter((a) => a.status === true)
    .some(
      (item) =>
        item.country === "uk" ||
        item.country === "america" ||
        item.country === "europe" ||
        item.country === "australia"
    );

  const resetValues = () => {
    setaccomplished([]);
    setmajors("");
    setsearchText("");
    // setsearchText1("");
    setcountries([]);
    setuniversities([]);
    setcountryVisited([
      { country: "europe", key: 3, status: false },
      { country: "australia", key: 0, status: false },
      { country: "america", key: 1, status: false },
      { country: "uk", key: 2, status: false },
      { country: "notVisited", key: 4, status: false },
    ]);
    setselectedCountry({});
    setselectedUniversity([]);
    setpreviousSchool("");
    setphoneNo("");
    setcgpa("");
    setselectedResidence({});
  };

  const englishLevel = [
    "Beginner",
    "Lower Intermediate",
    "Intermediate",
    "Upper Intermediate",
    "Advanced",
  ];
  return (
    <Modal show={props.show} fullscreen={true} onHide={() => props.onHide()}>
      <Modal.Header style={{ borderBottom: "none", width: "100%" }}>
        {/* <Modal.Title className="w-100 d-flex justify-content-between">
          <div />
          <MultiStepProgressBar
            page={page}
            selectedCountry={checkQualified}
            accomplished={accomplished}
          />
          <div
            onClick={() => {
              setPage("1");
              setaccomplished([]);
              setselectedSubject([]);
              setcountryVisited([
                { country: "أوروبا", key: 3, status: false },
                { country: "أستراليا", key: 0, status: false },
                { country: "أمريكا أو كندا", key: 1, status: false },
                { country: " المملكة المتحدة ", key: 2, status: false },
              ]);
              setselectedCountry("");
              setpreviousSchool("");
              setphoneNo("");
              setcgpa("");
              props.onHide();
            }}
          >
            <CircleCross style={{ width: 28, height: 28, cursor: "pointer" }} />
          </div>
        </Modal.Title> */}
        <Modal.Title className="w-100 d-flex justify-content-between align-items-center">
          {page === "1" ? (
            <div />
          ) : (
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (
                  (checkQualified && page === "11" && !sponsored) ||
                  (!checkQualified &&
                    page === "13" &&
                    !sponsored &&
                    moreThan50000)
                ) {
                  setPage(`${parseInt(page) - 2}`);
                  setsponsored(null);
                  setsponsoredBy("");
                } else if (!moreThan50000 && page === "13" && !sponsored) {
                  setPage(`${parseInt(page) - 5}`);
                } else {
                  setPage(`${parseInt(page) - 1}`);
                }
              }}
            >
              <BackIcon />
            </div>
          )}
          <ProgressBar
            now={
              !checkQualified
                ? parseInt(page) * 7.5
                : !checkQualified && !moreThan50000
                ? parseInt(page) * 11
                : checkQualified
                ? parseInt(page) * 9
                : 0
            }
          />
          <div
            onClick={() => {
              setPage("1");
              resetValues();
              props.onHide();
            }}
            className="d-flex align-items-center"
          >
            <CircleCross style={{ width: 28, height: 28, cursor: "pointer" }} />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className=" d-flex justify-content-center align-items-center">
        {!checkQualified ? (
          <div className="w-100">
            {page === "1" ? (
              <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                <QuestionText className="  " arabic={arabic}>
                  {t("common:question1")}
                </QuestionText>
                <OptionBtn
                  className="  "
                  arabic={arabic}
                  justifyContent="space-between"
                  onClick={() =>
                    onChangeDestination({ ar_name: "بريطانيا", en_name: "UK" })
                  }
                >
                  <div />
                  {t("common:uk")} <div style={{ fontSize: 24 }}>🇬🇧</div>
                </OptionBtn>
                <OptionBtn
                  className="  "
                  arabic={arabic}
                  justifyContent="space-between"
                  onClick={() =>
                    onChangeDestination({ ar_name: "غيرها", en_name: "Others" })
                  }
                >
                  <div />
                  {t("common:other")} <div style={{ fontSize: 24 }}>🌏</div>
                </OptionBtn>
              </div>
            ) : page === "2" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText className=" " arabic={arabic}>
                  {t("common:question2")}
                </QuestionText>

                <CountryInputComponent
                  selectedCountry={selectedCountry}
                  setsearchText={setsearchText}
                  setselectedCountry={setselectedCountry}
                  arabic={arabic}
                  searchCountry={searchCountry}
                  countries={countries}
                  setcountries={(a) => setcountries(a)}
                  searchText={searchText}
                  placeholder={`${t("common:nationalityPlaceholder")}`}
                />

                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}

                {Object.keys(selectedCountry).length ? (
                  <ButtonComponent
                    className=" "
                    label={t("common:continue")}
                    onClick={() => {
                      if (selectedCountry) {
                        accomplished.push("pagetwo");
                        nextPage("3");
                        seterrorMessage("");
                        setcountries([]);
                        setsearchText("");
                      } else {
                        seterrorMessage("Select country to continue");
                      }
                    }}
                    loading={false}
                    btnType={"dark"}
                    width="392px"
                    style={{ marginTop: 21, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "3" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question3")}
                </QuestionText>
                <OptionBtn
                  className=" "
                  justifyContent="center"
                  arabic={arabic}
                  onClick={() =>
                    onChangeDegree({
                      en_name: "Bachelor's",
                      ar_name: "البكلوريوس",
                    })
                  }
                >
                  {t("common:bachelor")}
                </OptionBtn>
                <OptionBtn
                  className=" "
                  justifyContent="center"
                  arabic={arabic}
                  onClick={() =>
                    onChangeDegree({
                      en_name: "Master's",
                      ar_name: "الماجستير",
                    })
                  }
                >
                  {t("common:masters")}
                </OptionBtn>
                <OptionBtn
                  className=" "
                  justifyContent="center"
                  arabic={arabic}
                  onClick={() =>
                    onChangeDegree({
                      en_name: "PhD",
                      ar_name: "الدكتوراة",
                    })
                  }
                >
                  {t("common:phd")}
                </OptionBtn>
              </div>
            ) : page === "4" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question4")}
                </QuestionText>

                <InputComponent
                  placeholder={`${t("common:majorPlaceholder")}`}
                  name="name"
                  onChange={(e) => setmajors(e.target.value)}
                  type="text"
                  className="form-input"
                  value={majors}
                  arabic={arabic}
                  autoComplete="off"
                />
                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}

                {majors ? (
                  <ButtonComponent
                    className=" "
                    label={t("common:continue")}
                    onClick={() => {
                      setcountries([]);
                      if (majors) {
                        accomplished.push("pagefour");
                        nextPage("5");
                        seterrorMessage("");
                      } else {
                        seterrorMessage("Select a subject to continue");
                      }
                    }}
                    loading={loading}
                    btnType={"dark"}
                    width="392px"
                    style={{ marginTop: 36, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "5" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question5")}
                </QuestionText>

                <InputComponent
                  placeholder={`${t("common:gradePlaceholder")}`}
                  name="name"
                  onChange={(e) => setcgpa(e.target.value)}
                  type="text"
                  className="form-input"
                  autoComplete="off"
                  value={cgpa}
                  arabic={arabic}
                />
                {cgpa ? null : (
                  <OptionBtn
                    className=""
                    justifyContent="center"
                    marginTop="15px"
                    arabic={arabic}
                    width={"max-content"}
                    onClick={() => {
                      setcgpa("Not Sure");
                      accomplished.push("pagefive");
                      nextPage("6");
                      seterrorMessage("");
                    }}
                  >
                    {t("common:notSure")}
                  </OptionBtn>
                )}

                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}
                {cgpa ? (
                  <ButtonComponent
                    className=" "
                    label={t("common:continue")}
                    onClick={() => {
                      if (cgpa) {
                        accomplished.push("pagefive");
                        nextPage("6");
                        seterrorMessage("");
                        // setcgpa("");
                      } else {
                        seterrorMessage("Answer the question to continue");
                      }
                    }}
                    loading={loading}
                    btnType={"dark"}
                    width="392px"
                    style={{ marginTop: 36, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "6" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question6NotQualified")}
                </QuestionText>
                <InputComponent
                  placeholder={`${t("common:schoolPlaceholder")}`}
                  name="previousSchool"
                  onChange={(e) => setpreviousSchool(e.target.value)}
                  type="text"
                  className="form-input"
                  value={previousSchool}
                  autoComplete="off"
                  arabic={arabic}
                />
                {previousSchool ? null : (
                  <OptionBtn
                    className=""
                    justifyContent="center"
                    marginTop="15px"
                    arabic={arabic}
                    width={"max-content"}
                    onClick={() => {
                      setpreviousSchool("Not Sure");
                      accomplished.push("pagesix");
                      nextPage("7");
                      seterrorMessage("");
                    }}
                  >
                    {t("common:notSure")}
                  </OptionBtn>
                )}
                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}
                {previousSchool ? (
                  <ButtonComponent
                    className=" "
                    label={t("common:continue")}
                    onClick={() => {
                      if (previousSchool) {
                        accomplished.push("pagesix");
                        nextPage("7");
                        seterrorMessage("");
                      } else {
                        seterrorMessage("Answere the question to continue");
                      }
                    }}
                    loading={loading}
                    btnType={"dark"}
                    width="392px"
                    style={{ marginTop: 36, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "7" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic} className="">
                  {t("common:forVisaPurpose")}
                </QuestionText>
                <QuestionText arabic={arabic}>
                  {t("common:question7NotQualified")}
                </QuestionText>

                <OptionBtn
                  className=""
                  arabic={arabic}
                  justifyContent="center"
                  onClick={() => {
                    accomplished.push("pageseven");
                    nextPage("8");
                    setmoreThan50000(true);
                  }}
                >
                  {t("common:yes")}
                </OptionBtn>
                <OptionBtn
                  className=" "
                  arabic={arabic}
                  justifyContent="center"
                  onClick={() => {
                    accomplished.push("pageseven");
                    nextPage("8");
                    setmoreThan50000(false);
                    setsponsored(null);
                    setsponsoredBy("");
                    setiltesScore("");
                    settakenIltes(false);
                  }}
                >
                  {t("common:no")}
                </OptionBtn>
              </div>
            ) : page === "8" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText className=" " arabic={arabic}>
                  {t("common:forVisaPurpose")}
                </QuestionText>
                <QuestionText className=" " arabic={arabic}>
                  {t("common:question8NotQualified")}
                </QuestionText>
                {countryVisited.map((a, key) => (
                  <CustomCheckbox
                    key={key}
                    label={t(`common:${a.country}`)}
                    arabic={arabic}
                    onChange={() => {
                      if (a.key !== 4 && a.status === false) {
                        const data = countryVisited.map((x) => {
                          if (x.key === a.key) {
                            x.status = true;
                          } else if (x.key === 4) {
                            x.status = false;
                          }
                          return x;
                        });
                        setcountryVisited(data);
                      } else if (a.key === 4 && a.status === false) {
                        const data = countryVisited.map((x) => {
                          if (x.key === a.key) {
                            x.status = true;
                          } else {
                            x.status = false;
                          }
                          return x;
                        });
                        setcountryVisited(data);
                      } else {
                        const data = countryVisited.map((x) => {
                          if (x.key === a.key) {
                            x.status = false;
                          }
                          return x;
                        });
                        setcountryVisited(data);
                      }
                    }}
                    checked={a.status === true}
                  />
                ))}

                {countryVisited.length ? (
                  <ButtonComponent
                    className=" "
                    label={t("common:continue")}
                    onClick={() => {
                      if (
                        countryVisited.some((item) => item.status === true) &&
                        moreThan50000
                      ) {
                        nextPage("9");
                      } else {
                        nextPage("13");
                      }
                    }}
                    loading={loading}
                    btnType={"dark"}
                    width="392px"
                    style={{ marginTop: 36, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "9" && moreThan50000 ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <>
                  <QuestionText arabic={arabic}>
                    {t("common:question9Qualified")}
                  </QuestionText>

                  <OptionBtn
                    className=""
                    // selected={takenIltes}
                    arabic={arabic}
                    justifyContent="center"
                    onClick={() => {
                      settakenIltes(true);
                      accomplished.push("pageten");
                      nextPage("10");
                    }}
                  >
                    {t("common:yes")}
                  </OptionBtn>
                  <OptionBtn
                    className=" "
                    arabic={arabic}
                    justifyContent="center"
                    onClick={() => {
                      settakenIltes(false);
                      accomplished.push("pageten");
                      nextPage("10");
                    }}
                  >
                    {t("common:no")}
                  </OptionBtn>
                </>
              </div>
            ) : page === "10" && moreThan50000 ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                {" "}
                {takenIltes === false ? (
                  <>
                    <QuestionText arabic={arabic}>
                      {t("common:whatsYourEngLevel")}
                    </QuestionText>

                    <OptionBtn
                      className=""
                      // selected={takenIltes}
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        setengLevel("beginner");
                        accomplished.push("pageeleven");
                        nextPage("11");
                      }}
                    >
                      {t("common:beginner")}
                    </OptionBtn>
                    <OptionBtn
                      className=" "
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        setengLevel("lowerIntermediate");
                        accomplished.push("pageeleven");
                        nextPage("11");
                      }}
                    >
                      {t("common:lowerIntermediate")}
                    </OptionBtn>
                    <OptionBtn
                      className=" "
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        setengLevel("intermediate");
                        accomplished.push("pageeleven");
                        nextPage("11");
                      }}
                    >
                      {t("common:intermediate")}
                    </OptionBtn>
                    <OptionBtn
                      className=" "
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        setengLevel("upperIntermediate");
                        accomplished.push("pageeleven");
                        nextPage("11");
                      }}
                    >
                      {t("common:upperIntermediate")}
                    </OptionBtn>
                    <OptionBtn
                      className=" "
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        setengLevel("advanced");
                        accomplished.push("pageeleven");
                        nextPage("11");
                      }}
                    >
                      {t("common:advanced")}
                    </OptionBtn>
                  </>
                ) : (
                  <>
                    <QuestionText arabic={arabic}>
                      {t("common:whatsYourScore")}
                    </QuestionText>
                    <InputComponent
                      placeholder={``}
                      name="iltesScore"
                      onChange={(e) => setiltesScore(e.target.value)}
                      type="number"
                      className="form-input"
                      value={iltesScore}
                      autoComplete="off"
                      arabic={arabic}
                    />
                    <ButtonComponent
                      className=" "
                      disableBtnStyle={!iltesScore}
                      label={t("common:continue")}
                      onClick={() => {
                        accomplished.push("pageeleven");
                        nextPage("11");
                      }}
                      loading={loading}
                      btnType={"dark"}
                      width="392px"
                      style={{ marginTop: 36, height: 56 }}
                    />
                  </>
                )}
              </div>
            ) : page === "11" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <>
                  <QuestionText arabic={arabic}>
                    {t("common:sponsorQuestion")}
                  </QuestionText>

                  <OptionBtn
                    className=""
                    // selected={takenIltes}
                    arabic={arabic}
                    justifyContent="center"
                    onClick={() => {
                      setsponsored(true);
                      accomplished.push("pagetwelve");
                      nextPage("12");
                    }}
                  >
                    {t("common:sponsored")}
                  </OptionBtn>
                  <OptionBtn
                    className=" "
                    arabic={arabic}
                    justifyContent="center"
                    onClick={() => {
                      setsponsored(false);
                      accomplished.push("pagethirteen");
                      nextPage("13");
                    }}
                  >
                    {t("common:selfFunded")}
                  </OptionBtn>
                </>
              </div>
            ) : page === "12" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:whoIsSponsoring")}
                </QuestionText>
                <InputComponent
                  placeholder={``}
                  name="sponsoredBy"
                  onChange={(e) => setsponsoredBy(e.target.value)}
                  type="text"
                  className="form-input"
                  value={sponsoredBy}
                  autoComplete="off"
                  arabic={arabic}
                />
                <ButtonComponent
                  className=""
                  disableBtnStyle={!sponsoredBy}
                  label={t("common:continue")}
                  onClick={() => {
                    accomplished.push("pagethirteen");
                    nextPage("13");
                  }}
                  loading={false}
                  btnType={"dark"}
                  width="392px"
                  style={{ marginTop: 36, height: 56 }}
                />
              </div>
            ) : page === "13" ? (
              <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                <FormContainer>
                  <Formik
                    // isInitialValid={}
                    initialValues={{
                      email: "",
                      lastName: "",
                      firstName: "",
                      residence: "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        setloading(true);
                        const conVisted = countryVisited.filter(
                          (a) => a.status === true
                        );
                        await createStudent({
                          email: values.email,
                          fullname: `${values.firstName} ${values.lastName}`,
                          phoneNo: formatPhoneNumberIntl(phoneNo),
                          residence: selectedResidence,
                          destination: destination,
                          iltesScore: iltesScore,
                          degree: degree,
                          subjects: majors,
                          source: "ulearn",
                          sponsoredBy: sponsoredBy,
                          sponsored: sponsored,
                          nationality: selectedCountry,
                          engLevel: engLevel || "N/A",
                          parentsIncome: moreThan50000,
                          countriesTraveled: conVisted.map((z) => z.country),
                          cgpa: cgpa,
                          previousSchool: previousSchool,
                          webUrl: window.location.href,
                        });
                        showAnimation(true);
                        const qualified = moreThan50000;
                        navigate(`/${i18n.language}/success`, {
                          state: { degree, qualified },
                        });
                        setloading(false);
                        setPage("1");
                        resetValues();
                      } catch (error) {
                        setloading(false);
                        alert(error.response.data.message);
                      }
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form
                        style={{ width: "70%" }}
                        className="d-flex w-100 flex-column"
                      >
                        <Row>
                          {arabic ? (
                            <>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:lastName")}
                                </Label>
                                <FormInputComponent
                                  className=" "
                                  id="lastName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="lastName"
                                  placeholder={`${t(
                                    "common:lastNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:firstName")}
                                </Label>
                                <FormInputComponent
                                  className=" "
                                  id="firstName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="firstName"
                                  placeholder={`${t(
                                    "common:firstNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:firstName")}
                                </Label>
                                <FormInputComponent
                                  className=" "
                                  id="firstName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="firstName"
                                  placeholder={`${t(
                                    "common:firstNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:lastName")}
                                </Label>
                                <FormInputComponent
                                  className=" "
                                  id="lastName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="lastName"
                                  placeholder={`${t(
                                    "common:lastNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                            </>
                          )}
                          <Col
                            className="mobile-view"
                            lg={6}
                            style={{ marginBottom: 12 }}
                          >
                            <Label arabic={arabic}>
                              {t("common:firstName")}
                            </Label>
                            <FormInputComponent
                              className=" "
                              id="firstName"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: arabic ? "rtl" : "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="firstName"
                              placeholder={`${t("common:firstNamePaceholder")}`}
                              type="text"
                            />
                          </Col>
                          <Col
                            className="mobile-view"
                            lg={6}
                            style={{ marginBottom: 12 }}
                          >
                            <Label arabic={arabic}>
                              {t("common:lastName")}
                            </Label>
                            <FormInputComponent
                              className=" "
                              id="lastName"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: arabic ? "rtl" : "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="lastName"
                              placeholder={`${t("common:lastNamePaceholder")}`}
                              type="text"
                            />
                          </Col>
                          <Col lg={12} style={{ marginBottom: 12 }}>
                            <Label arabic={arabic}>{t("common:email")}</Label>
                            <FormInputComponent
                              className=" "
                              id="email"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: arabic ? "rtl" : "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="email"
                              placeholder={`${t("common:emailPaceholder")}`}
                              type="email"
                            />
                          </Col>

                          <Col lg={12} style={{ marginBottom: 12 }}>
                            <Label arabic={arabic}>{t("common:phoneNo")}</Label>
                            <PhoneInput
                              international={false}
                              defaultCountry="KW"
                              value={phoneNo}
                              className={
                                arabic ? "phoneInput-ar" : "phoneInput-en"
                              }
                              onChange={(e) => setphoneNo(e)}
                              // countryCallingCode={false}
                              style={{
                                direction: arabic ? "rtl" : "ltr",
                              }}
                            />
                          </Col>
                          <Col lg={12}>
                            <Label arabic={arabic}>
                              {t("common:residence")}
                            </Label>
                            <CountryInputComponent
                              className=""
                              selectedCountry={selectedResidence}
                              setsearchText={setsearchText}
                              setselectedCountry={setselectedResidence}
                              arabic={arabic}
                              searchCountry={searchCountry}
                              countries={countries}
                              setcountries={setcountries}
                              searchText={searchText}
                              placeholder={`${t("common:residencePaceholder")}`}
                            />
                          </Col>
                        </Row>
                        <ButtonComponent
                          label={t("common:obtainDocs")}
                          loading={loading}
                          btnType={"dark"}
                          disableBtnStyle={
                            !phoneNo ||
                            !values.email ||
                            !values.firstName ||
                            !values.lastName ||
                            !Object.keys(selectedResidence).length
                          }
                          width="100%"
                          style={{
                            marginTop: 21,
                            height: 56,
                          }}
                        />
                      </Form>
                    )}
                  </Formik>
                </FormContainer>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="w-100">
            {page === "1" ? (
              <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question1")}
                </QuestionText>
                <OptionBtn
                  justifyContent="space-between"
                  onClick={() =>
                    onChangeDestination({ ar_name: "بريطانيا", en_name: "UK" })
                  }
                  arabic={arabic}
                >
                  <div />
                  {t("common:uk")} <div style={{ fontSize: 24 }}>🇬🇧</div>
                </OptionBtn>
                <OptionBtn
                  justifyContent="space-between"
                  arabic={arabic}
                  onClick={() =>
                    onChangeDestination({ ar_name: "غيرها", en_name: "Others" })
                  }
                >
                  <div />
                  {t("common:other")} <div style={{ fontSize: 24 }}>🌏</div>
                </OptionBtn>
              </div>
            ) : page === "2" ? (
              <div className="  d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question2")}
                </QuestionText>

                <CountryInputComponent
                  selectedCountry={selectedCountry}
                  setsearchText={setsearchText}
                  setselectedCountry={setselectedCountry}
                  arabic={arabic}
                  searchCountry={searchCountry}
                  countries={countries}
                  setcountries={setcountries}
                  searchText={searchText}
                  placeholder={`${t("common:nationalityPlaceholder")}`}
                />

                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}

                {Object.keys(selectedCountry).length ? (
                  <ButtonComponent
                    label={t("common:continue")}
                    onClick={() => {
                      if (selectedCountry) {
                        accomplished.push("pagetwo");
                        nextPage("3");
                        seterrorMessage("");
                        setcountries([]);
                        setsearchText("");
                      } else {
                        seterrorMessage("Select country to continue");
                      }
                    }}
                    width="392px"
                    loading={false}
                    btnType={"dark"}
                    style={{ marginTop: 36, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "3" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question3")}
                </QuestionText>
                <OptionBtn
                  justifyContent="center"
                  arabic={arabic}
                  onClick={() =>
                    onChangeDegree({
                      en_name: "Bachelor's",
                      ar_name: "البكلوريوس",
                    })
                  }
                >
                  {t("common:bachelor")}
                </OptionBtn>
                <OptionBtn
                  justifyContent="center"
                  arabic={arabic}
                  onClick={() =>
                    onChangeDegree({
                      en_name: "Master's",
                      ar_name: "الماجستير",
                    })
                  }
                >
                  {t("common:masters")}
                </OptionBtn>
                <OptionBtn
                  justifyContent="center"
                  arabic={arabic}
                  onClick={() =>
                    onChangeDegree({
                      en_name: "PhD",
                      ar_name: "الدكتوراة",
                    })
                  }
                >
                  {t("common:phd")}
                </OptionBtn>
              </div>
            ) : page === "4" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question4")}
                </QuestionText>
                <InputComponent
                  placeholder={`${t("common:majorPlaceholder")}`}
                  name="name"
                  onChange={(e) => setmajors(e.target.value)}
                  type="text"
                  className="form-input"
                  value={majors}
                  arabic={arabic}
                  autoComplete="off"
                />
                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}
                {majors ? (
                  <ButtonComponent
                    label={t("common:continue")}
                    onClick={() => {
                      setcountries([]);
                      if (majors) {
                        accomplished.push("pagefour");
                        nextPage("5");
                        seterrorMessage("");
                      } else {
                        seterrorMessage("Select subject to continue");
                      }
                    }}
                    loading={false}
                    width="392px"
                    btnType={"dark"}
                    style={{ marginTop: 36, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "5" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question5")}
                </QuestionText>

                <InputComponent
                  placeholder={`${t("common:gradePlaceholder")}`}
                  name="name"
                  onChange={(e) => setcgpa(e.target.value)}
                  type="text"
                  className="form-input"
                  value={cgpa}
                  arabic={arabic}
                  autoComplete="off"
                />
                {cgpa ? null : (
                  <OptionBtn
                    arabic={arabic}
                    width={"max-content"}
                    justifyContent="center"
                    marginTop="15px"
                    onClick={() => {
                      setcgpa("Not Sure");
                      accomplished.push("pagefive");
                      nextPage("6");
                      seterrorMessage("");
                    }}
                  >
                    {t("common:notSure")}{" "}
                  </OptionBtn>
                )}
                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}
                {cgpa ? (
                  <ButtonComponent
                    label={t("common:continue")}
                    onClick={() => {
                      if (cgpa) {
                        accomplished.push("pagefive");
                        nextPage("6");
                        seterrorMessage("");
                      } else {
                        seterrorMessage("Select option to continue");
                      }
                    }}
                    loading={loading}
                    btnType={"dark"}
                    width="392px"
                    style={{ marginTop: 36, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "6" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question9NotQualified")}
                </QuestionText>

                <UniversityInputComponent
                  selectedUniversity={selectedUniversity}
                  setselectedUniversity={setselectedUniversity}
                  setsearchText={setsearchText}
                  arabic={arabic}
                  universities={universities}
                  searchText={searchText}
                  searchUniversity={(e) => searchUniversity(e)}
                  t={t}
                  setuniversities={setuniversities}
                  seterrorMessage={seterrorMessage}
                  error={
                    errorMessage ? (
                      <ErrorMessage style={{ marginBottom: 5 }}>
                        {errorMessage}
                      </ErrorMessage>
                    ) : null
                  }
                />

                {selectedUniversity?.length ? null : (
                  <OptionBtn
                    arabic={arabic}
                    justifyContent="center"
                    onClick={() => {
                      nextPage("7");
                      accomplished.push("pagesix");
                      // setselectedUniversity([t("common:notSure")]);
                    }}
                    style={{
                      marginBottom: 0,
                      width: "max-content",
                    }}
                  >
                    {t("common:notSure")}
                  </OptionBtn>
                )}
                {selectedUniversity.length ? (
                  <ButtonComponent
                    label={t("common:continue")}
                    onClick={() => {
                      nextPage("7");
                      accomplished.push("pagesix");
                    }}
                    loading={loading}
                    btnType={"dark"}
                    width="392px"
                    style={{ marginTop: 20, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "7" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question9Qualified")}
                </QuestionText>

                <OptionBtn
                  className=""
                  // selected={takenIltes}
                  arabic={arabic}
                  justifyContent="center"
                  onClick={() => {
                    settakenIltes(true);
                    accomplished.push("pageeight");
                    nextPage("8");
                  }}
                >
                  {t("common:yes")}
                </OptionBtn>
                <OptionBtn
                  className=" "
                  arabic={arabic}
                  justifyContent="center"
                  onClick={() => {
                    settakenIltes(false);
                    accomplished.push("pageeight");
                    nextPage("8");
                  }}
                >
                  {t("common:no")}
                </OptionBtn>
              </div>
            ) : page === "8" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                {takenIltes === false ? (
                  <>
                    <QuestionText arabic={arabic}>
                      {t("common:whatsYourEngLevel")}
                    </QuestionText>

                    <OptionBtn
                      className=""
                      // selected={takenIltes}
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        setengLevel("beginner");
                        accomplished.push("pagenine");
                        nextPage("9");
                      }}
                    >
                      {t("common:beginner")}
                    </OptionBtn>
                    <OptionBtn
                      className=" "
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        setengLevel("lowerIntermediate");
                        accomplished.push("pagenine");
                        nextPage("9");
                      }}
                    >
                      {t("common:lowerIntermediate")}
                    </OptionBtn>
                    <OptionBtn
                      className=" "
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        setengLevel("intermediate");
                        accomplished.push("pagenine");
                        nextPage("9");
                      }}
                    >
                      {t("common:intermediate")}
                    </OptionBtn>
                    <OptionBtn
                      className=" "
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        setengLevel("upperIntermediate");
                        accomplished.push("pagenine");
                        nextPage("9");
                      }}
                    >
                      {t("common:upperIntermediate")}
                    </OptionBtn>
                    <OptionBtn
                      className=" "
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        setengLevel("advanced");
                        accomplished.push("pagenine");
                        nextPage("9");
                      }}
                    >
                      {t("common:advanced")}
                    </OptionBtn>
                  </>
                ) : (
                  <>
                    <QuestionText arabic={arabic}>
                      {t("common:whatsYourScore")}
                    </QuestionText>
                    <InputComponent
                      placeholder={``}
                      name="iltesScore"
                      onChange={(e) => setiltesScore(e.target.value)}
                      type="number"
                      className="form-input"
                      value={iltesScore}
                      autoComplete="off"
                      arabic={arabic}
                    />
                    <ButtonComponent
                      className=" "
                      disableBtnStyle={!iltesScore}
                      label={t("common:continue")}
                      onClick={() => {
                        accomplished.push("pagenine");
                        nextPage("9");
                      }}
                      loading={loading}
                      btnType={"dark"}
                      width="392px"
                      style={{ marginTop: 36, height: 56 }}
                    />
                  </>
                )}
              </div>
            ) : page === "9" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <>
                  <QuestionText arabic={arabic}>
                    {t("common:sponsorQuestion")}
                  </QuestionText>

                  <OptionBtn
                    className=""
                    // selected={takenIltes}
                    arabic={arabic}
                    justifyContent="center"
                    onClick={() => {
                      setsponsored(true);
                      accomplished.push("pageten");
                      nextPage("10");
                    }}
                  >
                    {t("common:sponsored")}
                  </OptionBtn>
                  <OptionBtn
                    className=" "
                    arabic={arabic}
                    justifyContent="center"
                    onClick={() => {
                      setsponsored(false);
                      accomplished.push("pageeleven");
                      nextPage("11");
                    }}
                  >
                    {t("common:selfFunded")}
                  </OptionBtn>
                </>
              </div>
            ) : page === "10" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:whoIsSponsoring")}
                </QuestionText>
                <InputComponent
                  placeholder={``}
                  name="sponsoredBy"
                  onChange={(e) => setsponsoredBy(e.target.value)}
                  type="text"
                  className="form-input"
                  value={sponsoredBy}
                  autoComplete="off"
                  arabic={arabic}
                />
                <ButtonComponent
                  className=""
                  disableBtnStyle={!sponsoredBy}
                  label={t("common:continue")}
                  onClick={() => {
                    accomplished.push("pageeleven");
                    nextPage("11");
                  }}
                  loading={false}
                  btnType={"dark"}
                  width="392px"
                  style={{ marginTop: 36, height: 56 }}
                />
              </div>
            ) : page === "11" ? (
              <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                <FormContainer>
                  <Formik
                    initialValues={{
                      email: "",
                      lastName: "",
                      firstName: "",
                      residence: "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      setloading(true);

                      await createStudent({
                        email: values.email,
                        fullname: `${values.firstName} ${values.lastName}`,
                        phoneNo: formatPhoneNumberIntl(phoneNo),
                        residence: selectedResidence,
                        destination: destination,
                        degree: degree,
                        source: "ulearn",
                        selectedUniversity: selectedUniversity.length
                          ? selectedUniversity
                          : "not sure",
                        subjects: majors,
                        nationality: selectedCountry,
                        iltesScore: iltesScore,
                        engLevel: engLevel || "N/A",
                        sponsoredBy: sponsoredBy,
                        sponsored: sponsored,
                        cgpa: cgpa,
                        webUrl: window.location.href,
                      });
                      showAnimation(true);
                      const qualified = true;
                      navigate(`/${i18n.language}/success`, {
                        state: { degree, qualified },
                      });
                      setPage("1");
                      resetValues();
                      setloading(false);
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form
                        style={{ width: "70%" }}
                        className="d-flex w-100 flex-column"
                      >
                        <Row>
                          {arabic ? (
                            <>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:lastName")}
                                </Label>
                                <FormInputComponent
                                  className=" "
                                  id="lastName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="lastName"
                                  placeholder={`${t(
                                    "common:lastNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:firstName")}
                                </Label>
                                <FormInputComponent
                                  className=" "
                                  id="firstName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="firstName"
                                  placeholder={`${t(
                                    "common:firstNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:firstName")}
                                </Label>
                                <FormInputComponent
                                  className=" "
                                  id="firstName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="firstName"
                                  placeholder={`${t(
                                    "common:firstNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:lastName")}
                                </Label>
                                <FormInputComponent
                                  className=" "
                                  id="lastName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="lastName"
                                  placeholder={`${t(
                                    "common:lastNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                            </>
                          )}
                          <Col
                            className="mobile-view"
                            lg={6}
                            style={{ marginBottom: 12 }}
                          >
                            <Label arabic={arabic}>
                              {t("common:firstName")}
                            </Label>
                            <FormInputComponent
                              className=" "
                              id="firstName"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: arabic ? "rtl" : "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="firstName"
                              placeholder={`${t("common:firstNamePaceholder")}`}
                              type="text"
                            />
                          </Col>
                          <Col
                            className="mobile-view"
                            lg={6}
                            style={{ marginBottom: 12 }}
                          >
                            <Label arabic={arabic}>
                              {t("common:lastName")}
                            </Label>
                            <FormInputComponent
                              className=" "
                              id="lastName"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: arabic ? "rtl" : "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="lastName"
                              placeholder={`${t("common:lastNamePaceholder")}`}
                              type="text"
                            />
                          </Col>

                          <Col md={12} style={{ marginBottom: 12 }}>
                            <Label arabic={arabic}>{t("common:email")}</Label>
                            <FormInputComponent
                              id="email"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="email"
                              placeholder={`${t("common:emailPaceholder")}`}
                              type="email"
                            />
                          </Col>

                          <Col md={12} style={{ marginBottom: 12 }}>
                            <Label arabic={arabic}>{t("common:phoneNo")}</Label>

                            <PhoneInput
                              international={false}
                              defaultCountry="KW"
                              value={phoneNo}
                              className={
                                arabic ? "phoneInput-ar" : "phoneInput-en"
                              }
                              onChange={(e) => setphoneNo(e)}
                              // countryCallingCode={false}
                            />
                            {/* <PhonenoComponent /> */}
                          </Col>
                          <Col md={12}>
                            <Label arabic={arabic}>
                              {t("common:residence")}
                            </Label>
                            <CountryInputComponent
                              selectedCountry={selectedResidence}
                              setsearchText={setsearchText}
                              setselectedCountry={setselectedResidence}
                              arabic={arabic}
                              searchCountry={searchCountry}
                              countries={countries}
                              setcountries={setcountries}
                              searchText={searchText}
                              placeholder={`${t("common:residencePaceholder")}`}
                            />
                          </Col>
                        </Row>
                        <ButtonComponent
                          label={t("common:obtainDocs")}
                          loading={loading}
                          btnType={"dark"}
                          disableBtnStyle={
                            !phoneNo ||
                            !values.email ||
                            !values.firstName ||
                            !values.lastName ||
                            !Object.keys(selectedResidence).length
                          }
                          width="100%"
                          style={{ marginTop: 21, height: 56 }}
                        />
                      </Form>
                    )}
                  </Formik>
                </FormContainer>
              </div>
            ) : null}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
