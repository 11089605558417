export const countryList = [
  "الولايات المتحدة",
  "بريطانيا",
  "الكويت",
  "السعودية",
  "الإمارات",
  "قطر",
  "البحرين",
  "عمان",
  "مصر",
  "الأردن",
  "كندا",
  "أستراليا",
  "ألمانيا",
  "تركيا",
  "فرنسا",
  "أسبانيا",
  "أوكرانيا",
  "روسيا",
  "قبرص",
  "أخرى",
];

export const NationalityArabic = [
  "الكويت",
  "السعودية",
  "الإكوادور",
  "الإمارات العربية المتحدة",
  "الأرجنتين",
  "الأردن",
  "البحرين",
  "البرازيل",
  "البرتغال",
  "البوسنة والهرسك",
  "البيرو",
  "التشيك",
  "الجزائر",
  "الدنمارك",
  "السلفادور",
  "السنغال",
  "السودان",
  "السويد",
  "الصومال",
  "الصين",
  "العراق",
  "الغابون",
  "الفلبين",
  "الكاميرون",
  "الكونغو",
  "الكونغو الديمقراطية",
  "المجر",
  "المغرب",
  "المكسيك",
  "المملكة المتحدة",
  "النرويج",
  "النمسا",
  "النيجر",
  "الهند",
  "الولايات المتحدة",
  "اليابان",
  "اليمن",
  "اليونان",
  "إثيوبيا",
  "إسبانيا",
  "إستونيا",
  "إندونيسيا",
  "إيران",
  "إيطاليا",
  "أذربيجان",
  "أرمينيا",
  "أستراليا",
  "أفريقيا الوسطى",
  "أفغانستان",
  "ألبانيا",
  "ألمانيا",
  "أندورا",
  "أنغولا",
  "أوروغواي",
  "أوزبكستان",
  "أوغندا",
  "أوكرانيا",
  "أيرلندا",
  "آيسلندا",
  "باراغواي",
  "باكستان",
  "بلجيكا",
  "بلغاريا",
  "بنما",
  "بنين",
  "بوتسوانا",
  "بولندا",
  "بوليفيا",
  "بيلاروس",
  "تايلاند",
  "تركيا",
  "تشاد",
  "تشيلي",
  "تنزانيا",
  "توغو",
  "تونس",
  "جزر القمر",
  "جنوب السودان",
  "جنوب أفريقيا",
  "جورجيا",
  "جيبوتي",
  "روسيا",
  "رومانيا",
  "زامبيا",
  "زيمبابوي",
  "ساحل العاج",
  "سريلانكا",
  "سلوفاكيا",
  "سلوفينيا",
  "سنغافورة",
  "سوريا",
  "سويسرا",
  "سيراليون",
  "صربيا",
  "عمان",
  "غانا",
  "غينيا",
  "فرنسا",
  "فلسطين",
  "فنزويلا",
  "فنلندا",
  "فيتنام",
  "قبرص",
  "قطر",
  "كازاخستان",
  "كرواتيا",
  "كمبوديا",
  "كندا",
  "كوبا",
  "كوريا الجنوبية",
  "كوريا الشمالية",
  "كوستاريكا",
  "كولومبيا",
  "كينيا",
  "لاتفيا",
  "لبنان",
  "لوكسمبورغ",
  "ليبيا",
  "ليبيريا",
  "ليتوانيا",
  "مالطا",
  "مالي",
  "ماليزيا",
  "مدغشقر",
  "مصر",
  "مقدونيا الشمالية",
  "منغوليا",
  "موريتانيا",
  "موزمبيق",
  "مولدوفا",
  "موناكو",
  "نيبال",
  "نيجيريا",
  "نيكاراجوا",
  "نيوزيلندا",
  "هندوراس",
  "هولندا",
  "أخرى",
];
