import React from "react";

function CheckCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        stroke="#299CF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 11.708v.92a10 10 0 11-5.93-9.14"
      ></path>
      <path
        stroke="#299CF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 4.628l-10 10.01-3-3"
      ></path>
    </svg>
  );
}

export default CheckCircle;
