import React, { Component } from "react";
import Slider from "react-slick";

export default class LogoSlider extends Component {
  render() {
    var settings = {
      dots: true,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 4000,
      slidesToShow: 3,
      rows: 2,
      // rtl: true,
      infinite: true,
      slidesToScroll: 3,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
            arrows: false,
            rows: 2,
            infinite: true,
            // rtl: true,
          },
        },
      ],
    };
    return (
      <div>
        <Slider className="logo-slick-slider" {...settings}>
          <div className="logo-slider-img-con">
            <img
              className="img1"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo1.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img4"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo2.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img3"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo3.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img5"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo4.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img2"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo5.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img6"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo6.png"
              alt="icon"
            />
          </div>

          <div className="logo-slider-img-con">
            <img
              className="img7"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo7.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img8"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo8.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img9"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo9.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img10"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo10.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img11"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo11.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img12"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo12.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img13"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo13.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img14"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo14.png"
              alt="icon"
            />
          </div>

          <div className="logo-slider-img-con">
            <img
              className="img15"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo15.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img16"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo16.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img17"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo17.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img18"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo18.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img19"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo19.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img20"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo20.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img21"
              src="https://cdn.ulearn.study/courses/ulearnabroad%20PartnerLogo/partner-logo21.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img22"
              src="https://storage.googleapis.com/destination_ulearn/courses/partner%20logo%20ulearnabroad/partner-logo16.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img23"
              src="https://storage.googleapis.com/destination_ulearn/courses/ulearnabroad%20PartnerLogo/partner-logo23.png"
              alt="icon"
            />
          </div>
          <div className="logo-slider-img-con">
            <img
              className="img24"
              src="https://storage.googleapis.com/destination_ulearn/courses/ulearnabroad%20PartnerLogo/partner-logo24.png"
              alt="icon"
            />
          </div>
        </Slider>
      </div>
    );
  }
}
